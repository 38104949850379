import { TIMEOUT_ERROR_CODE } from 'config';
import { useSnackbar } from 'notistack';
import { useContext } from 'react';
import {
  MutationFunction,
  QueryFunction,
  QueryKey,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions
} from 'react-query';
import { Pager } from './api';
import ApiContext, { IApiContext } from './context';
import { SnackbarAction } from './SnackbarAction';

export interface PageParams {
  page: number,
  pageSize: number
}

export interface PagedListOfT<T> {
  items: T[];
  pager: Pager;
}

const retry = (failureCount: number, error: any) => (
  error
  && error.status
  && error.status === TIMEOUT_ERROR_CODE
  && failureCount < 2
);

const useApi = (): IApiContext => {
  const context = useContext(ApiContext);
  if (context === null) {
    throw new Error('No api context available');
  }
  return context;
};

export const makeListQuery = <P, Data>(
  key: string,
  queryFnFactory: (api: IApiContext, params: P) => QueryFunction<Data>) => (params: P, options?: UseQueryOptions<Data>) => {
    const api = useApi();
    return useQuery(
      params ? [key, params] : [key],
      queryFnFactory(api, params),
      { retry, keepPreviousData: true, ...options },
    );
  };

export const makeInfiniteListQuery = <P, Data>(
  key: string,
  queryFnFactory: (api: IApiContext, params?: P) => QueryFunction<PagedListOfT<Data>>,
) => (params?: P, options?: UseInfiniteQueryOptions<PagedListOfT<Data>>) => {
  const api = useApi();

  return useInfiniteQuery(
    key,
    queryFnFactory(api, params),
    {
      getPreviousPageParam: (firstPage) => {
        if (firstPage.pager.page !== 1) {
          return {
            page: firstPage.pager.page - 1,
            pageSize: firstPage.pager.pageSize,
          };
        }

        return false;
      },
      getNextPageParam: (lastPage) => {
        if (lastPage.pager.page < lastPage.pager.pageCount) {
          return {
            page: lastPage.pager.page + 1,
            pageSize: lastPage.pager.pageSize,
          };
        }

        return false;
      },
      retry,
      ...options,
    },
  );
};

export const makeGetQuery = <TGet, TParams = any>(
  key: string,
  queryFnFactory: (api: IApiContext, params: TParams) => QueryFunction<TGet>,
) => (params: TParams, options?: UseQueryOptions<TGet>) => {
  const api = useApi();
  return useQuery(
    params ? [key, params] : [key],
    queryFnFactory(api, params),
    { retry, ...options },
  );
};

export const makeCreateMutation = <TData, TModel>(
  listKey: string | null,
  mutationFnFactory: (api: IApiContext) => MutationFunction<TData, TModel>,
  successMessageFn: (model: TModel) => string,
  invalidateKeys: string[] = []) => (options?: UseMutationOptions<TData, unknown, TModel>) => {
    const api = useApi();
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();
    return useMutation<TData, unknown, TModel>(
      mutationFnFactory(api),
      {
        ...options,
        onSuccess: (...args) => {
          if (options?.onSuccess) {
            options.onSuccess(...args);
          }
          enqueueSnackbar(successMessageFn(args[1]), {
            action: SnackbarAction,
          });

          listKey && queryClient.invalidateQueries(listKey, { exact: false });
          invalidateKeys.forEach((key) => queryClient.invalidateQueries(key, { exact: false }));
        },
        retry,
      },
    );
  };

export const makeUpdateMutation = <TData, TModel>(
  getKey: string,
  listKey: string | null,
  mutationFnFactory: (api: IApiContext, entityId: string) => MutationFunction<TData, TModel>,
  successMessageFn: (model: TModel) => string,
  invalidateKeys: string[] = []) => (entityId: string, options?: UseMutationOptions<TData, unknown, TModel>) => {
    const api = useApi();
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();
    return useMutation<TData, unknown, TModel>(
      mutationFnFactory(api, entityId),
      {
        ...options,
        onSuccess: (...args) => {
          if (options?.onSuccess) {
            options.onSuccess(...args);
          }

          enqueueSnackbar(successMessageFn(args[1]));

          listKey && queryClient.invalidateQueries(listKey, { exact: false });
          queryClient.invalidateQueries([getKey, entityId]);
          invalidateKeys.forEach((key) => queryClient.invalidateQueries(key, { exact: false }));
        },
        retry,
      },
    );
  };

export const makeDeleteMutation = <TData, TModel>(
  getKey: string,
  listKey: string | null,
  mutationFnFactory: (api: IApiContext) => MutationFunction<TData, TModel>,
  successMessageFn: (model: TModel) => string,
  invalidationId: (model: TModel) => string,
  invalidateKeys: string[] = []) => (options?: UseMutationOptions<TData, unknown, TModel>) => {
    const api = useApi();
    const queryClient = useQueryClient();

    const { enqueueSnackbar } = useSnackbar();

    return useMutation<TData, unknown, TModel>(
      mutationFnFactory(api),
      {
        ...options,
        onSuccess: (...args) => {
          if (options?.onSuccess) {
            options.onSuccess(...args);
          }

          enqueueSnackbar(successMessageFn(args[1]));

          listKey && queryClient.invalidateQueries(listKey, { exact: false });
          queryClient.invalidateQueries([getKey, invalidationId(args[1])]);
          invalidateKeys.forEach((key) => queryClient.invalidateQueries(key, { exact: false }));
        },
        retry,
      },
    );
  };

  export const makeRemoveQueries = (queryKey: QueryKey | undefined) => {
    const queryClient = useQueryClient();

    const execute = () => queryClient.removeQueries({ queryKey });

    return {
      execute,
    };
  };

export default useApi;
