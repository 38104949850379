import {
 createStyles, IconButton, makeStyles, Theme
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useSnackbar } from 'notistack';
import React from 'react';

interface SnackbarActionProps {
  snackbarId: string;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    closeButton: {
        color: theme.palette.secondary.dark,
    },
}));

export const SnackbarAction: React.FC<SnackbarActionProps> = ({ snackbarId }) => {
    const { closeSnackbar } = useSnackbar();
    const classes = useStyles();
    return (
      <>
        <IconButton
          onClick={() => closeSnackbar(snackbarId)}
        >
          <CloseIcon className={classes.closeButton} />
        </IconButton>
      </>
    );
};

export default SnackbarAction;
