import { round } from 'utils';
import { distanceMetricToImperial } from 'utils/unitConversions';
import PObject, { PObjectParams } from '../../DiagraExtended/PObject';
import * as constants from '../../GConstants';

export interface LineGraphParams extends PObjectParams {
  x?: number;
  y?: number;
  width?: number;
  height?: number;
  xMargin?: number;
  yMargin?: number;
  title?: string;
  func?: {
    type?: number; // input %m, output yield stress
    f: (x: number, y?: number) => number;
    coeffs?: number[] | undefined;
  };
  data?: number[][];
  xMin?: number;
  yMin?: number;
  xMax?: number;
  yMax?: number;
  xPlotLo?: number;
  xPlotHi?: number;
  yPlotLo?: number;
  yPlotHi?: number;
  xInterval?: number;
  yInterval?: number;
  xLabel?: string;
  yLabel?: string;
  labelPos?: string;
  xScale?: number; // metres per pixel
  yScale?: number;
  colour1?: string;
  plotCol?: string[];
  xInvert?: number; // applies to graph numerical values
  yInvert?: number;
  xMirror?: number; // mirror applies to graph layout
  yMirror?: number;
  xYFlip?: number;
  xAxisOff?: number;
  yAxisOff?: number;
  xAxisFloat?: number;
  yAxisFloat?: number;
  xLine?: number;
  yLine?: number;
  legend?: number;
  legendPos?: string;
  legendKey?: string[];
  textScale?: number;
}

const scale = (a: number, b: number) => a / b;

export default class LineGraph extends PObject {
  x: number;

  y: number;

  width: number;

  height: number;

  xMargin: number;

  yMargin: number;

  title: string;

  func?: {
    type?: number; // input %m, output yield stress
    f: (x: number, y?: number) => number;
    coeffs?: number[] | undefined;
  };

  data: number[][] = [];

  xMin: number;

  yMin: number;

  xMax: number;

  yMax: number;

  xPlotLo: number;

  xPlotHi: number;

  yPlotLo: number;

  yPlotHi: number;

  xInterval: number;

  yInterval: number;

  xLabel: string;

  yLabel: string;

  labelPos: string;

  xScale: number; // metres per pixel

  yScale: number;

  colour1: string;

  plotCol: string[];

  xInvert: number; // applies to graph numerical values

  yInvert: number;

  xMirror: number; // mirror applies to graph layout

  yMirror: number;

  xYFlip: number;

  xAxisOff: number;

  yAxisOff: number;

  xAxisFloat: number;

  yAxisFloat: number;

  xLine: number;

  yLine: number;

  legend: number;

  legendPos: string;

  legendKey: string[];

  private tickSize = 4;

  private xFlip = 0;

  private yFlip = 0;

  textScale: number;

  constructor(params: LineGraphParams) {
    super(params);
    this.x = params.x || 0;
    this.y = params.y || 0;
    this.width = params.width || 200;
    this.height = params.height || 200;
    this.xMargin = params.xMargin || this.width / 10;
    this.yMargin = params.yMargin || this.height / 10;
    this.title = params.title || 'no title';
    this.func = params.func;
    this.data = [];
    this.xMin = params.xMin || 0;
    this.yMin = params.yMin || 0;
    this.xMax = params.xMax || 0;
    this.yMax = params.yMax || 0;
    this.xPlotLo = params.xPlotLo || 0;
    this.xPlotHi = params.xPlotHi || 0;
    this.yPlotLo = params.yPlotLo || 0;
    this.yPlotHi = params.yPlotHi || 0;
    this.xInterval = params.xInterval || 10;
    this.yInterval = params.yInterval || 10;
    this.xLabel = params.xLabel || '';
    this.yLabel = params.yLabel || '';
    this.labelPos = params.labelPos || 'centre-out';
    this.xScale = 0; // metres per pixel
    this.yScale = 0;
    this.colour1 = params.colour1 || '#fff';
    this.plotCol = params.plotCol || ['#fff'];
    this.xInvert = params.xInvert || 0; // applies to graph numerical values
    this.yInvert = params.yInvert || 0;
    this.xMirror = params.xMirror || 0; // mirror applies to graph layout
    this.yMirror = params.yMirror || 0;
    this.xYFlip = params.xYFlip || 0;
    this.xAxisOff = params.xAxisOff || 0;
    this.yAxisOff = params.yAxisOff || 0;
    this.xAxisFloat = params.xAxisFloat || 0;
    this.yAxisFloat = params.yAxisFloat || 0;
    this.xLine = params.xLine || 0;
    this.yLine = params.yLine || 0;
    this.legend = params.legend || 0;
    this.legendPos = params.legendPos || 'top-left'; // Options include 'top-left', 'top-right', 'bottom-left' and 'bottom-right'
    this.legendKey = params.legendKey || [];
    this.textScale = params.textScale || 10;
  }

  // public hitTestX(mouseX: number) {
  //   // provides x coordinate within the canvas
  //   // Check where on the x axis the mouse is located
  //   // Return the index of the point

  //   const x = mouseX - this.xMargin; // mouse x coordinates within the plot area
  //   let m = this.width;
  //   let idx;
  //   const xCoords = this.data[0];
  //   for (let i = 0; i < xCoords.length; i += 1) {
  //     const d = Math.abs(xCoords[i] / this.xScale - x);
  //     if (d < m) {
  //       m = d;
  //       idx = i;
  //     }
  //   }
  //   return idx;
  // }

  public refresh() {
    // Determines range and scales for axes
    // determine if the axis is flipped due to inversion of numbers or mirroring
    this.xFlip = (this.xInvert + this.xMirror) % 2;
    this.yFlip = (this.yInvert + this.yMirror) % 2;

    // is the function inverted, so x = f(y) ? If so, ensure the x values are given to data[1]
    // const xyfa = this.xYFlip;
    // let xyfb = this.xYFlip + 1;
    // xyfb %= 2;

    // // determine x and y values of the given function (if given)
    // if (this.func) {
    //   const interval = 50;
    //   // Setup the first values in the data arrays
    //   if (xyfa) {
    //     this.data = [[this.output(this.xPlotLo)], [this.xPlotLo]]; // flip x and y if inverse function
    //   } else {
    //     this.data = [[this.xPlotLo], [this.output(this.xPlotLo)]];
    //   }
    //   const xint = (this.xPlotHi - this.xPlotLo) / interval;
    //   // Determine the full range of data values
    //   for (let i = 0; i < interval; i += 1) {
    //     const x = this.xPlotLo + i * xint;
    //     this.data[xyfa].push(x);
    //     this.data[xyfb].push(this.output(x));
    //   }
    // }

    /**
     * When closing the stopes panel from React UI unselectStope from cmodel.ts is called and the the data array is reset.
     * refresh() is still called but data is empty. Below checks for this scenario can returns early.
     */
    if (!this.data[0] || !this.data[1]) return;
    // Check for valid values in arrays
    for (let i = 0; i < this.data.length; i += 1) {
      if (LineGraph.checkForNAN(this.data[i])) {
        this.data[i] = [];
      }
    }

    // determine range and plot scale

    this.xMin = Math.min(...this.data[0]);
    this.xMax = Math.max(...this.data[0]);
    let yAxisValues = this.data[1];

    // combine all y axes dataset arrays for evaluation of max/min/scale
    if (this.data.length > 1) {
      for (let i = 2; i < this.data.length; i += 1) {
        if (this.data[i][0] !== undefined) {
          yAxisValues = yAxisValues.concat(this.data[i]);
        }
      }
    }

    this.yMin = Math.min(...yAxisValues);
    // if (this.yMin < 0) this.yMin = 0;
    this.yMax = Math.max(...yAxisValues);
    let ymn = this.yMin;
    let ymx = this.yMax;
    let xmn = this.xMin;
    let xmx = this.xMax;
    let ypl = this.yPlotLo;
    let yph = this.yPlotHi;
    let xpl = this.xPlotLo;
    let xph = this.xPlotHi;

    if (this.xYFlip) {
      xmn = this.xMin;
      xmx = this.xMax;
      ymn = this.yMin;
      ymx = this.yMax;
      xpl = this.yPlotLo;
      xph = this.yPlotHi;
      ypl = this.xPlotLo;
      yph = this.xPlotHi;
    }

    if (this.xAxisFloat) {
      this.xScale = scale(xmx - xmn, this.width - 2 * this.xMargin);
    } else {
      this.xScale = scale(xph - xpl, this.width - 2 * this.xMargin);
    }

    if (this.yAxisFloat) {
      this.yScale = scale(ymx - ymn, this.height - 2 * this.yMargin);
    } else {
      this.yScale = scale(yph - ypl, this.height - 2 * this.yMargin);
    }

    // this.yLine = this.output(this.xLine);
  }

  // public output(x: number, y?: number) { // Evaluate a function
  //   if (this.func) {
  //     const { type, coeffs } = this.func;
  //     let z = 0;
  //     if (type === 0 && coeffs) { // Function a polynomial
  //       for (let i = 0; i < coeffs.length; i += 1) {
  //         z += coeffs[i] * (x ** i);
  //       }
  //       return z;
  //     }

  //     if (type === 1 && coeffs) { // Power function
  //       if (coeffs.length > 2) {
  //         throw new Error('Too many function terms');
  //       } else {
  //         return (coeffs[0] * (x ** coeffs[1]));
  //       }
  //     }

  //     if (type === 2 && this.func.f) {
  //       return (this.func.f(x));
  //     }

  //     if (type === 3 && this.func.f) {
  //       return (this.func.f(x, y));
  //     }
  //     return 0;
  //   }

  //   return 0;
  // }

  public layout(context: CanvasRenderingContext2D, unitSystemPreference: number) {
    const c = context;
    c.strokeStyle = this.colour1;
    c.fillStyle = this.colour1;

    // Title of plot
    c.textAlign = 'center';
    c.font = `${this.textScale}px Verdana`;

    // axes
    // main lines (relies on individual canvas for plot)
    let xPosY;
    let yPosX; // set up mirrored conditions

    if (this.yMirror) { // Position of x-axis
      xPosY = this.yMargin;
    } else {
      xPosY = this.height - this.yMargin;
    }

    if (this.xMirror) { // Position of y-axis
      yPosX = this.width - this.xMargin;
    } else {
      yPosX = this.xMargin;
    }

    c.beginPath(); // x axis
    c.moveTo(this.xMargin, xPosY);
    c.lineTo(this.width - this.xMargin, xPosY);
    // y axis
    c.moveTo(yPosX, this.yMargin);
    c.lineTo(yPosX, this.height - this.yMargin);
    c.stroke();

    const ymn = this.yMin;
    const ymx = this.yMax;
    const xmn = this.xMin;
    const xmx = this.xMax;
    let ypl = this.yPlotLo;
    let yph = this.yPlotHi;
    let xpl = this.xPlotLo;
    let xph = this.xPlotHi;
    let xint = this.xInterval;
    let yint = this.yInterval;
    let pxint = this.xInterval / this.xScale;
    let pyint = this.yInterval / this.yScale;

    if (this.xYFlip) {
      ypl = this.xPlotLo;
      yph = this.xPlotHi;
      xpl = this.yPlotLo;
      xph = this.yPlotHi;
      xint = this.yInterval;
      yint = this.xInterval;
      pxint = this.yInterval / this.xScale;
      pyint = this.xInterval / this.yScale;
    }

    // Axis labels
    // centre-out = centre outside
    // centre-in = centre inside

    // xPosY = y position of x axis
    // yPosX = x position of y axis
    const xlabX = this.width / 2;
    const ylabY = this.height / 2;
    const ylabX = 0;
    let xlabY;
    const xlabOffset = 5;

    if (this.yMirror === 1) { // x axis label in y direction
      if (this.labelPos === 'center-out') {
        xlabY = 2 * xlabOffset;
      } else {
        xlabY = 3 * xlabOffset + this.yMargin;
      }
    } else if (this.labelPos === 'center-out') {
      xlabY = this.height - xlabOffset;
    } else {
      xlabY = this.height - xlabOffset - this.yMargin;
    }
    c.save();
    c.textAlign = 'center';
    c.font = `${this.textScale}px Arial`;
    c.save();
    let xAxisText = this.xLabel;
    let yAxisText = this.yLabel;
    if (unitSystemPreference === 5) {
      xAxisText = `${this.xLabel} (m)`;
      yAxisText = `${this.yLabel} (m)`;
    } else {
      xAxisText = `${this.xLabel} (ft)`;
      yAxisText = `${this.yLabel} (ft)`;
    }
    c.fillText(xAxisText, xlabX, xlabY);
    if (this.xMirror === 1) {
      c.translate(this.width, 0);
      c.rotate(3 * (Math.PI / 2));
      c.fillText(yAxisText, -1 * ylabY, -1 * ylabX - 10);
    } else {
      c.translate(0, this.height);
      c.rotate(3 * (Math.PI / 2));
      if (this.labelPos === 'center-out') {
        c.fillText(yAxisText, ylabY, ylabX + 10);
      } else {
        c.fillText(yAxisText, ylabY, ylabX + (2 * this.yMargin) + 5);
      }
    }
    c.restore();

    // vertical delineation

    if (!this.yAxisOff) {
      let y: number;
      if (this.yFlip) {
        y = this.yMargin;
      } else {
        y = this.height - this.yMargin;
      }

      c.font = `${this.textScale}px Arial`;
      c.textAlign = 'right';

      let min = ypl;
      let max = yph;

      if (this.yAxisFloat) { // is delineation by y values, or fixed?
        min = ymn;
        max = ymx;
      }
      if (unitSystemPreference === 10) {
        min = distanceMetricToImperial(min);
        max = distanceMetricToImperial(max);
        yint = distanceMetricToImperial(yint);
      }
      let t = min;
      let count = 0;
      while (count < max / yint) {
        c.beginPath();
        c.moveTo(yPosX - this.tickSize, y);
        c.lineTo(yPosX + this.tickSize, y);
        c.stroke();
        c.fillText(`${round(t, 0)}`, yPosX - 5, y);
        if (this.yFlip) {
          y += pyint;
        } else {
          y -= pyint;
        }
        t += yint;
        count += 1;
        if (count > 1000) {
          break;
        }
      }
    }
    if (this.yLine) {
      c.save();
      c.strokeStyle = constants.CYAN;
      c.beginPath();
      c.moveTo(0, this.yVal(this.yLine));
      c.lineTo(this.width, this.yVal(this.yLine));
      c.stroke();
      c.restore();
    }

    // horizontal delineation
    if (!this.xAxisOff) {
      let x;
      if (this.xFlip) {
        x = this.width - this.xMargin;
      } else {
        x = this.xMargin;
      }

      c.font = `${this.textScale}px Arial`;
      c.textAlign = 'center';

      let min = xpl;
      let max = xph;

      if (this.yAxisFloat) { // is delineation by y values, or fixed?
        min = xmn;
        max = xmx;
      }
      if (unitSystemPreference === 10) {
        min = distanceMetricToImperial(min);
        max = distanceMetricToImperial(max);
        xint = distanceMetricToImperial(xint);
      }
      const xAxisTextPos = this.xYFlip + this.yMirror;
      let xAxisTextOffset;
      if (xAxisTextPos === 2) {
        xAxisTextOffset = -5;
      } else {
        xAxisTextOffset = 11;
      }
      let t = min;
      let count = 0;
      while (count < max / xint) {
        c.beginPath();
        c.moveTo(x, xPosY - this.tickSize);
        c.lineTo(x, xPosY + this.tickSize);
        c.stroke();
        c.fillText(`${round(t, 0)}`, x, xPosY + xAxisTextOffset);
        if (this.xFlip) {
          x -= pxint;
        } else {
          x += pxint;
        }
        t += xint;
        count += 1;
        if (count > 1000) {
          break;
        }
      }
    }

    if (this.xLine) {
      c.save();
      c.strokeStyle = constants.ORANGE;
      c.beginPath();
      c.moveTo(this.xVal(this.xLine), this.yMargin);
      c.lineTo(this.xVal(this.xLine), this.height - this.yMargin);
      c.stroke();
      c.restore();
    }
  }

  public yVal(y: number) {
    if (this.yFlip) {
      return (y / this.yScale + this.yMargin);
    }
    return (this.height - this.yMargin - y / this.yScale);
  }

  public xVal(x: number) {
    if (this.xFlip) {
      return (this.width - this.xMargin - x / this.xScale);
    }
    return (x / this.xScale + this.xMargin);
  }

  plot(context: CanvasRenderingContext2D, unitSystemPreference: number) {
    if (this.data[0]) {
      const c = context;

      const { data } = this;
      const p = data.length;
      const q = data[0].length;
      let yOffset;
      c.fillStyle = constants.BACKGROUND_COLOR;
      c.globalAlpha = 0.6;
      c.fillRect(0, 0, this.width, this.height);
      c.globalAlpha = 1;
      let count = 0;
      for (let i = 1; i < p; i += 1) {
        if (!LineGraph.checkForNAN(data[i]) && data[i].length > 0) {
          // draw each of the data sets (multiple y for single x in case of HGL)
          c.strokeStyle = this.plotCol[i - 1];
          let x = data[0][0];
          let y = data[i][0];
          c.beginPath();
          c.moveTo(this.xVal(x), this.yVal(y - this.yMin));
          for (let j = 1; j < q; j += 1) {
            x = data[0][j];
            y = data[i][j];
            c.lineTo(this.xVal(x), this.yVal(y - this.yMin));
          }
          c.stroke();
          // Draw legend
          let keyText = '';
          if (this.legendKey.length >= (i)) {
            keyText = this.legendKey[i - 1];
          }
          yOffset = 10 * (count);
          let legendX;
          let legendY;
          c.save();
          c.fillStyle = constants.GREY;
          c.textAlign = 'left';
          if (this.legend) {
            if (this.legendPos === 'top-left') {
              legendX = 2 * this.xMargin;
              legendY = this.yMargin + yOffset;
            } else if (this.legendPos === 'top-right') {
              legendX = this.width - 2 * this.xMargin - 40;
              legendY = this.yMargin + yOffset;
            } else if (this.legendPos === 'bottom-left') {
              legendX = 2 * this.xMargin;
              legendY = this.height - (3 * this.yMargin) + yOffset;
            } else {
              legendX = this.width - 2 * this.xMargin - 40;
              legendY = this.height - (3 * this.yMargin) + yOffset;
            }
            c.beginPath();
            const legendYPos = legendY + this.textScale * (i / 1.3);
            c.moveTo(legendX, legendYPos);
            c.lineTo(legendX + 20, legendYPos);
            c.stroke();
            c.fillText(keyText, legendX + 30, legendYPos);
          }
          c.restore();
          count += 1;
        }
      }
      this.layout(c, unitSystemPreference);
    }
  }

  static checkForNAN(arr: number[]) {
    if (arr.includes(NaN)) {
      console.log('error in graph data', arr);
      return true;
    }
    return false;
  }
}
