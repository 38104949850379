// import Point from '../Diagra/Point';
import Spool from '../Diagra/Spool';
import { SpoolJSON } from '../Diagra/types';
import * as GConstants from '../GConstants';
import * as PCConstants from './PCConstants';
import PCNode from './PCNode';
import PCPipe from './PCPipe';

export default class PCSpool extends Spool {
  allowFlowAB: boolean;

  allowFlowBA: boolean;

  pipe?: PCPipe;

  isFlowingFromAtoB?: boolean;

  constructor(
    data: SpoolJSON,
    points: Record<string, PCNode>,
    pipes: Record<string, PCPipe>,
    allowFlowAB: boolean,
    allowFlowBA: boolean,
  ) {
    super(data, points, pipes);
    this.allowFlowAB = allowFlowAB;
    this.allowFlowBA = allowFlowBA;
    if (points) this.pointA = Object.values(points).find((o) => this.pointAId === o.pointId);
    if (points) this.pointB = Object.values(points).find((o) => this.pointBId === o.pointId);
    if (pipes) {
      this.pipe = Object.values(pipes).find((o) => this.pipeId.toString() === o.pipeId.toString());
      const pipeId = parseInt(data.pipeId, 10);
      this.pipeColour = GConstants.PIPE_COLOURS[pipeId - 1];
    }
  }

  getFrictionLoss(): number { // kPa, negative for loss
    let frictionLoss = 0;
    if (this.pipe && this.pipe.friction) frictionLoss = -1 * this.length() * this.pipe.friction;
    // else console.log('no pipe or no friction', this);
    return frictionLoss;
  }

  getPressureDelta(wetDensity: number, flowForward?: boolean): number { // kPa, negative for loss
    const frictionLoss = this.getFrictionLoss();
    let head; // positive when pipe descends, negative when pipe ascends
    if (!flowForward && this.allowFlowBA) head = this.deltaZ(); // deltaZ = this.pointA.coords.z - this.pointB.coords.z;
    else if (this.allowFlowAB) head = -1 * this.deltaZ();
    else console.log('flow not allowed in given direction');
    let totalPressureDelta = 0;
    if (typeof head !== 'undefined' && wetDensity) {
      const pressureFromHead = (wetDensity * 9.81 * head) / 1000; // P = rho*g*h in kPa
      totalPressureDelta = pressureFromHead - frictionLoss;
    } // else console.log('error: no head calculated, check recipe wetDensity', head, wetDensity);
    return totalPressureDelta; // kPa
  }

  renderSpool(ctx: CanvasRenderingContext2D, colour?: string | CanvasGradient | CanvasPattern) {
    if (this.pointA && this.pointB) {
      // if (this.hitBool || this.selected) {
      //   this.render(ctx);
      if (colour) { // overide standard render if colour specified
        this.drawSpool(ctx, colour);
      // } else this.drawSpool(ctx, this.colour);
        } else this.render(ctx);
    }
  }

  drawSpool(ctx: CanvasRenderingContext2D, colour: string | CanvasGradient | CanvasPattern) {
      ctx.fillStyle = colour;
      ctx.strokeStyle = colour;
      if (this.selected) ctx.lineWidth = PCConstants.SPOOL_LINE_WIDTH * 3;
      else ctx.lineWidth = PCConstants.SPOOL_LINE_WIDTH;
      ctx.beginPath();
      ctx.moveTo(this.pointA!.screenVector.x, this.pointA!.screenVector.y);
      ctx.lineTo(this.pointB!.screenVector.x, this.pointB!.screenVector.y);
      ctx.closePath();
      ctx.stroke();
    }
  }
