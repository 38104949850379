import { calculateViscosityfromConcentrationByMass, calculateYieldfromConcentrationByMass } from 'utils/backfill';
import ModelState from './ModelState';
import updateHGL from './hgl/updateHGL';

function homogeneousNonNewtonian(
  rheoA: number,
  rheoB: number,
  rheoC: number,
  rheoD: number,
  concentration: number,
  pipeInnerDiameter: number,
  velocity: number,
) {
  let t0Laminar: number;
  let low: number;
  let high: number;

  let lhs: number;
  let rhs: number;
  let rhs1: number;
  let rhs2: number;
  let counter: number;

  const flowBehaviourIndex = 1;
  const yieldStress = calculateYieldfromConcentrationByMass(concentration * 100, rheoA, rheoB);
  const fluidConsistencyIndex = calculateViscosityfromConcentrationByMass(concentration * 100, rheoC, rheoD);

  // Laminar Flow calculations
  // Initialize
  t0Laminar = yieldStress;
  low = yieldStress;
  high = 100000;
  counter = 0;

  do {
    counter += 1;
    lhs = (8 * velocity) / pipeInnerDiameter;

    t0Laminar = (low + high) / 2;
    // eslint-disable-next-line max-len, no-mixed-operators
    rhs1 = (4 * flowBehaviourIndex) / (t0Laminar ** 3) * ((1 / fluidConsistencyIndex) ** (1 / flowBehaviourIndex)) * ((t0Laminar - yieldStress) ** ((flowBehaviourIndex + 1) / flowBehaviourIndex));
    // eslint-disable-next-line max-len
    rhs2 = ((t0Laminar - yieldStress) ** 2) / (3 * flowBehaviourIndex + 1) + (2 * yieldStress * (t0Laminar - yieldStress)) / (2 * flowBehaviourIndex + 1) + (yieldStress ** 2) / (flowBehaviourIndex + 1);

    rhs = rhs1 * rhs2;

    if (rhs > lhs) high = t0Laminar;
    if (rhs < lhs) low = t0Laminar;

    if (velocity < 0 || counter > 1000) {
      console.log('Friction Input Error');
      break;
    }
  } while (lhs - rhs >= 0.0001 || rhs - lhs >= 0.0001);

  const wallShearStress = t0Laminar;

  return (wallShearStress * 4) / (pipeInnerDiameter * 1000); // kPa
}

function homogeneousNewtonian(pipeInnerDiameter: number, velocity: number, slurryDensity: number) {
  const fluidConsistencyIndex = 0.000894; // water viscosity (Pa.s) at 25 degrees
  const Re = (slurryDensity * velocity * pipeInnerDiameter) / fluidConsistencyIndex;
  const wallRoughness = 0.000050; // typical wall roughness for lightly worn steel pipe
  const A = (2.457 * Math.log((((7 / Re) ** 0.9) + (0.27 * (wallRoughness / pipeInnerDiameter))) ** -1)) ** 16;
  const B = (37530 / Re) ** 16;
  const frictionFactor = 2 * (((8 / Re) ** 12) + ((A + B) ** -1.5)) ** 0.0833; // Churchill(1977) correlation

  const pGrad = (2 * slurryDensity * frictionFactor * (velocity ** 2)) / pipeInnerDiameter;
  return pGrad / 1000;
}

function calculateFriction(
  rheoA: number,
  rheoB: number,
  rheoC: number,
  rheoD: number,
  concentration: number,
  pipeInnerDiameter: number,
  velocity: number,
  slurryDensity: number,
  frictionLossFactorOfSafety? : number,
) {
  const safetyFactor = frictionLossFactorOfSafety || 1;
  if (rheoA === 0) {
    return homogeneousNewtonian(pipeInnerDiameter, velocity, slurryDensity) * safetyFactor;
  }
    return homogeneousNonNewtonian(rheoA, rheoB, rheoC, rheoD, concentration, pipeInnerDiameter, velocity) * safetyFactor;
}

// maybe use the RecipeState?
export default function updateFriction(
  modelState: ModelState,
) {
  const { recipeState, fluid, pipes, selectedRoute } = modelState;
  const { wetFlowRate, totalDryConcentrationByMass, tailingsDryTonnage, binderConcentrationByMass, wetDensity } = recipeState;
  if (typeof wetFlowRate !== 'undefined'
    && typeof totalDryConcentrationByMass !== 'undefined'
    && typeof tailingsDryTonnage !== 'undefined'
    && typeof binderConcentrationByMass !== 'undefined'
    && typeof wetDensity !== 'undefined'
    ) {
    Object.values(pipes).forEach((pipe) => {
      const friction = calculateFriction(
        fluid.rheologyCoefficients.a,
        fluid.rheologyCoefficients.b,
        fluid.rheologyCoefficients.c,
        fluid.rheologyCoefficients.d,
        totalDryConcentrationByMass,
        pipe.insideDiameter,
        pipe.getVelocity(wetFlowRate),
        wetDensity,
        fluid.frictionLossFactorOfSafety,
      );

      pipe.setFriction(friction);
      pipe.getVelocity(wetFlowRate);
    });

    if (typeof selectedRoute !== 'undefined') {
      selectedRoute.updateRoutePointData(recipeState);
      selectedRoute.colourRouteByPressure();
      updateHGL(modelState.hglData, selectedRoute, recipeState);
    }
  }
}
