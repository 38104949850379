import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { GridFooterContainer, GridPagination } from '@material-ui/data-grid';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { throughputUnitLabelFromThroughputControlType } from 'hooks/useDisplayUnits/display';
import useDisplayUnits, {
  AdditionalDisplayUnitsInputs,
  BasicDisplayUnits,
  convertIfRequired,
  getAbbreviation,
  UnitBase,
  UnitBaseWithValue,
  unitLabels
} from 'hooks/useDisplayUnits/useDisplayUnits';
import { Parser, transforms } from 'json2csv';
import {
  CustomUnitFields,
  DisplayUnits,
  FillType,
  PourReportDto,
  PourStatus,
  RheologyDataDto,
  ThroughputControlType,
  UnitSystem
} from 'providers/api';
import React from 'react';
import { round } from 'utils';

const useStyles = makeStyles((theme: Theme) => createStyles({
  link: {
    marginLeft: theme.spacing(1),
    '&:hover': {
      textDecoration: 'none',
    },
  },
}));

const getValue = (preference: UnitSystem, unitInfo: UnitBaseWithValue) => (preference === UnitSystem.Metric
  ? unitInfo.value
  : round(unitInfo.imperial.conversion(unitInfo.value ?? 0), 2));

interface PoursTableFooterProps {
  pours: PourReportDto[];
  rheologyDataIndexedById: Record<string, RheologyDataDto>;
  projectName: string;
  displayUnitPreferences: DisplayUnits[];
  customUnitFields: CustomUnitFields[],
  unitSystemPreference: UnitSystem;
  fillType: FillType;
  throughputControlType: ThroughputControlType;
}

const PoursTableFooter = ({
  pours,
  rheologyDataIndexedById,
  projectName,
  displayUnitPreferences,
  unitSystemPreference,
  customUnitFields,
  fillType,
  throughputControlType,
}: PoursTableFooterProps) => {
  const classes = useStyles();
  const [downloadLink, setDownloadLink] = React.useState('');

  const additionalUnitInputs: AdditionalDisplayUnitsInputs[] = pours.map((pour) => ({
    key: pour.entityId,
    displayUnitPreferences,
    specification: fillType === FillType.Paste ? pour.pasteSpecification : pour.hydraulicSpecification,
    selectedSpecification: fillType === FillType.Paste ? pour.pasteSelectedSpecification : pour.hydraulicSelectedSpecification,
    // Rheology data
    specificGravity: pour.rheologyDataSetId ? rheologyDataIndexedById[pour.rheologyDataSetId].tailingsDrySolidsDensity : undefined,
    yieldStressA: pour.rheologyDataSetId ? rheologyDataIndexedById[pour.rheologyDataSetId].yieldStressCoefficients.coefficient1 : undefined,
    yieldStressB: pour.rheologyDataSetId ? rheologyDataIndexedById[pour.rheologyDataSetId].yieldStressCoefficients.coefficient2 : undefined,
    mixerCoefficients: pour.rheologyDataSetId ? rheologyDataIndexedById[pour.rheologyDataSetId].mixerCoefficients : undefined,
    heightOfCylinder: pour.rheologyDataSetId ? rheologyDataIndexedById[pour.rheologyDataSetId].heightOfCylinder : undefined,
    fillType,
    throughputControlType,
  }));
  const rheologyDataSetReference = rheologyDataIndexedById[pours[0].rheologyDataSetId].reference;
  const additionalUnitsIndexedByPourId = useDisplayUnits(additionalUnitInputs);
  const unitString = (unitSystem: UnitSystem, unitInfo: UnitBase, value?: number) => {
    if (!value) return 'no data';
    const convertedValue = round(convertIfRequired(value, unitSystem, unitInfo, customUnitFields), 2);
    const abbreviation = getAbbreviation(unitInfo, unitSystem, customUnitFields);
    return `${convertedValue} ${abbreviation}`;
  };

  type ProjectSpecificUnits = {
    [key: string]: string;
  };

  const processData = () => {
    if (pours.length > 0) {
      const processedPours = pours.map((pour) => {
        // update values in pour
        const pourWithConversion = {
          ...pour,
          volume: unitString(unitSystemPreference, unitLabels[BasicDisplayUnits.Volume], pour.volume),
          pasteSpecification: {
            targetUCSStrength: pour.pasteSpecification === null || pour.pasteSpecification === undefined
            ? ''
            : unitString(unitSystemPreference, unitLabels[BasicDisplayUnits.PressureUCS], pour.pasteSpecification?.targetUCSStrength),
            targetDays: `${pour.pasteSpecification?.targetDays ?? 0} days`,
            dryTonnage: pour.pasteSpecification === null || pour.pasteSpecification === undefined || throughputControlType !== ThroughputControlType.DryTonnage
            ? ''
            : unitString(unitSystemPreference,
            throughputUnitLabelFromThroughputControlType(throughputControlType),
            throughputControlType === ThroughputControlType.DryTonnage ? pour.pasteSpecification?.throughput : undefined),
            flowRate: pour.pasteSpecification === null || pour.pasteSpecification === undefined || throughputControlType !== ThroughputControlType.FlowRate
            ? ''
            : unitString(unitSystemPreference,
            throughputUnitLabelFromThroughputControlType(throughputControlType),
            throughputControlType === ThroughputControlType.FlowRate ? pour.pasteSpecification?.throughput : undefined),
            wetTonnage: pour.pasteSpecification === null || pour.pasteSpecification === undefined || throughputControlType !== ThroughputControlType.WetTonnage
            ? ''
            : unitString(unitSystemPreference,
            throughputUnitLabelFromThroughputControlType(throughputControlType),
            throughputControlType === ThroughputControlType.WetTonnage ? pour.pasteSpecification?.throughput : undefined),
            pumpPressure: pour.pasteSpecification === null || pour.pasteSpecification === undefined
            ? ''
            : unitString(unitSystemPreference, unitLabels[BasicDisplayUnits.PressurePump], pour.pasteSpecification?.pumpPressure),
          },
          pasteOptimisedSpecification: {
            massConcentration: pour.pasteOptimisedSpecification === null || pour.pasteOptimisedSpecification === undefined
            ? ''
            : `${pour.pasteOptimisedSpecification?.massConcentration} %m`,
            binderContent: pour.pasteOptimisedSpecification === null || pour.pasteOptimisedSpecification === undefined
            ? ''
            : `${pour.pasteOptimisedSpecification?.binderContent} %b`,
            ucsStrength: pour.pasteOptimisedSpecification === null || pour.pasteOptimisedSpecification === undefined
            ? ''
            : unitString(unitSystemPreference, unitLabels[BasicDisplayUnits.PressureUCS], pour.pasteOptimisedSpecification?.ucsStrength),
            pressure: pour.pasteOptimisedSpecification === null || pour.pasteOptimisedSpecification === undefined
            ? ''
            : unitString(unitSystemPreference, unitLabels[BasicDisplayUnits.PressurePump], pour.pasteOptimisedSpecification?.pumpPressure),
          },
          pasteSelectedSpecification: {
            massConcentration: pour.pasteSelectedSpecification === null || pour.pasteSelectedSpecification === undefined
            ? ''
            : `${pour.pasteSelectedSpecification?.massConcentration} %m`,
            binderContent: pour.pasteSelectedSpecification === null || pour.pasteSelectedSpecification === undefined
            ? ''
            : `${pour.pasteSelectedSpecification?.binderContent} %b`,
            ucsStrength: pour.pasteSelectedSpecification === null || pour.pasteSelectedSpecification === undefined
            ? ''
            : unitString(unitSystemPreference, unitLabels[BasicDisplayUnits.PressureUCS], pour.pasteSelectedSpecification?.ucsStrength),
            pressure: pour.pasteSelectedSpecification === null || pour.pasteSelectedSpecification === undefined
            ? ''
            : unitString(unitSystemPreference, unitLabels[BasicDisplayUnits.PressurePump], pour.pasteSelectedSpecification?.pumpPressure),
          },
          hydraulicSpecification: {
            dryTonnage: pour.hydraulicSpecification === null || pour.hydraulicSpecification === undefined || throughputControlType !== ThroughputControlType.DryTonnage
            ? ''
            : unitString(unitSystemPreference,
            throughputUnitLabelFromThroughputControlType(throughputControlType),
            throughputControlType === ThroughputControlType.DryTonnage ? pour.hydraulicSpecification?.throughput : undefined),
            flowRate: pour.hydraulicSpecification === null || pour.hydraulicSpecification === undefined || throughputControlType !== ThroughputControlType.FlowRate
            ? ''
            : unitString(unitSystemPreference,
            throughputUnitLabelFromThroughputControlType(throughputControlType),
            throughputControlType === ThroughputControlType.FlowRate ? pour.hydraulicSpecification?.throughput : undefined),
            wetTonnage: pour.hydraulicSpecification === null || pour.hydraulicSpecification === undefined || throughputControlType !== ThroughputControlType.WetTonnage
            ? ''
            : unitString(unitSystemPreference,
            throughputUnitLabelFromThroughputControlType(throughputControlType),
            throughputControlType === ThroughputControlType.WetTonnage ? pour.hydraulicSpecification?.throughput : undefined),
            binderTailingsRatio: pour.hydraulicSpecification === null || pour.hydraulicSpecification === undefined
            ? ''
            : `1:${Math.round(100 / (pour.hydraulicSpecification?.binderTailingsRatio as number * 100))}`,
          },
          hydraulicOptimisedSpecification: {
            massConcentration: pour.hydraulicOptimisedSpecification === null || pour.hydraulicOptimisedSpecification === undefined
            ? ''
            : `${pour.hydraulicOptimisedSpecification?.massConcentration} %m`,
            maxPipePressure: pour.hydraulicOptimisedSpecification === null || pour.hydraulicOptimisedSpecification === undefined
            ? ''
            : unitString(unitSystemPreference, unitLabels[BasicDisplayUnits.PressurePump], pour.hydraulicOptimisedSpecification?.maxPipePressure),
            minVelocity: pour.hydraulicOptimisedSpecification === null || pour.hydraulicOptimisedSpecification === undefined
            ? ''
            : unitString(unitSystemPreference, unitLabels[BasicDisplayUnits.Speed], pour.hydraulicOptimisedSpecification?.minVelocity),
            settlingVelocity: pour.hydraulicOptimisedSpecification === null || pour.hydraulicOptimisedSpecification === undefined
            ? ''
            : unitString(unitSystemPreference, unitLabels[BasicDisplayUnits.Speed], pour.hydraulicOptimisedSpecification?.settlingVelocity),
          },
          hydraulicSelectedSpecification: {
            massConcentration: pour.hydraulicSelectedSpecification === null || pour.hydraulicSelectedSpecification === undefined
            ? ''
            : `${pour.hydraulicSelectedSpecification?.massConcentration} %m`,
            maxPipePressure: pour.hydraulicSelectedSpecification === null || pour.hydraulicSelectedSpecification === undefined
            ? ''
            : unitString(unitSystemPreference, unitLabels[BasicDisplayUnits.PressurePump], pour.hydraulicSelectedSpecification?.maxPipePressure),
            minVelocity: pour.hydraulicSelectedSpecification === null || pour.hydraulicSelectedSpecification === undefined
            ? ''
            : unitString(unitSystemPreference, unitLabels[BasicDisplayUnits.Speed], pour.hydraulicSelectedSpecification?.minVelocity),
            settlingVelocity: pour.hydraulicSelectedSpecification === null || pour.hydraulicSelectedSpecification === undefined
            ? ''
            : unitString(unitSystemPreference, unitLabels[BasicDisplayUnits.Speed], pour.hydraulicSelectedSpecification?.settlingVelocity),
          },
          pourData: {
            operatorLogNoteFile: pour.pourData?.operatorLogNoteFile,
            pourCompletedDate: pour.pourData?.pourCompletedDate,
            dryTonnage: pour.pourData === null || pour.pourData === undefined || throughputControlType !== ThroughputControlType.DryTonnage
            ? ''
            : unitString(unitSystemPreference, throughputUnitLabelFromThroughputControlType(ThroughputControlType.DryTonnage), pour.pourData?.dryTonnage),
            massConcentration: pour.pourData === null || pour.pourData === undefined
            ? ''
            : `${pour.pourData?.massConcentration} %m`,
            binderContent: pour.pourData === null || pour.pourData === undefined
            ? ''
            : `${pour.pourData?.binderContent} %b`,
            volume: pour.pourData === null || pour.pourData === undefined
            ? ''
            : unitString(unitSystemPreference, unitLabels[BasicDisplayUnits.Volume], pour.pourData?.volume),
            totalBinder: pour.pourData?.totalBinder,
            comments: pour.pourData?.comments,
            binderTailingsRatio: pour.pourData === null || pour.pourData === undefined
            ? ''
            : `1:${Math.round(100 / (pour.pourData?.binderTailingsRatio as number * 100))}`,
            volumeRetainedAfterOutflow: pour.pourData === null || pour.pourData === undefined
            ? ''
            : unitString(unitSystemPreference, unitLabels[BasicDisplayUnits.Volume], pour.pourData?.volumeRetainedAfterOutflow),
          },
          rheologyDataSetReference,
        };

        const additionalUnitsDisplay: ProjectSpecificUnits = Object.entries(additionalUnitsIndexedByPourId[pour.entityId].additionalDisplayUnits)
        .reduce((displayValues, [/* key */, unitInfo]) => ({
          ...displayValues,
          ...(unitInfo.value && {
            [` ${unitInfo.name}`]: `${getValue(unitSystemPreference, unitInfo)} ${getAbbreviation(unitInfo, unitSystemPreference, customUnitFields)}`,
          }),
        }), {});

      return {
        ...pourWithConversion,
        projectSpecificUnits: additionalUnitsDisplay,
      };
    });

      const modifiedPasteReportData = processedPours.map((pour) => ({
          'Backfill Plant': pour.spoolIds && pour.spoolIds.length > 0 ? pour.spoolIds[pour.spoolIds.length - 1] : '',
          Status: PourStatus[pour.status],
          'Pour Reference': pour.reference,
          'Stope Name': pour.stopeData?.stopeName,
          'Pour Date': pour.intendedPourDate.toLocalDateTime().toString(),
          'Target Volume': pour.volume,
          'UCS Target': pour.pasteSpecification?.targetUCSStrength,
          'UCS TargetDays': pour.pasteSpecification?.targetDays,
          'Dry Tonnage': pour.pasteSpecification?.dryTonnage,
          'Wet Tonnage': pour.pasteSpecification?.wetTonnage,
          'Flow Rate': pour.pasteSpecification?.flowRate,
          'Pump Pressure Maximum': pour.pasteSpecification?.pumpPressure,
          'Design Mass Concentration': pour.pasteOptimisedSpecification?.massConcentration,
          'Design Binder Content': pour.pasteOptimisedSpecification?.binderContent,
          'Design UCS Strength': pour.pasteOptimisedSpecification?.ucsStrength,
          'Design Pump Pressure': pour.pasteOptimisedSpecification?.pressure,
          'Selected Mass Concentration': pour.pasteSelectedSpecification?.massConcentration,
          'Selected Binder Content': pour.pasteSelectedSpecification?.binderContent,
          'Selected UCS Strength': pour.pasteSelectedSpecification?.ucsStrength,
          'Selected Pump Pressure': pour.pasteSelectedSpecification?.pressure,
          'Slurry Density': pour.projectSpecificUnits[' Slurry Density'],
          'Solids Density': pour.projectSpecificUnits[' Solids Density'],
          'Slurry Tonnage': pour.projectSpecificUnits[' Slurry Tonnage'],
          Slump: pour.projectSpecificUnits[' Slump'],
          Warnings: pour.warnings,
          'Rheology Data Set': rheologyDataSetReference,
          'Pour Completion Date': pour.pourData?.pourCompletedDate,
          'Pour Completion Dry Tonnage': pour.pourData?.dryTonnage,
          'Pour Completion Mass Concentration': pour.pourData?.massConcentration,
          'Pour Completion Binder Content': pour.pourData?.binderContent,
          'Pour Completion Volume': pour.pourData?.volume,
          'Pour Completion Total Cement': pour.pourData?.totalBinder,
          'Pour Completion Additional Comments': pour.pourData?.comments,
          'Pour Completion Binder Tailings Ratio': pour.pourData?.binderTailingsRatio,
          'Pour Completion Volume Retained After Outflow': pour.pourData?.volumeRetainedAfterOutflow,
        }));

      const modifiedHydraulicReportData = processedPours.map((pour) => ({
        'Backfill Plant': pour.spoolIds && pour.spoolIds.length > 0 ? pour.spoolIds[pour.spoolIds.length - 1] : '',
        Status: PourStatus[pour.status],
        'Pour Reference': pour.reference,
        'Stope Name': pour.stopeData?.stopeName,
        'Pour Date': pour.intendedPourDate.toLocalDateTime().toString(),
        'Target Volume': pour.volume,
        'Dry Tonnage': pour.hydraulicSpecification?.dryTonnage,
        'Wet Tonnage': pour.hydraulicSpecification?.wetTonnage,
        'Flow Rate': pour.hydraulicSpecification?.flowRate,
        'Binder Tailings Ratio': pour.hydraulicSpecification?.binderTailingsRatio,
        'Design Pipe Pressure Maximum': pour.hydraulicOptimisedSpecification?.maxPipePressure,
        'Design Mass Concentration': pour.hydraulicOptimisedSpecification?.massConcentration,
        'Design Minimum Velocity': pour.hydraulicOptimisedSpecification?.minVelocity,
        'Design Settling Velocity': pour.hydraulicOptimisedSpecification?.settlingVelocity,
        'Design Pump Pressure': pour.pasteOptimisedSpecification?.pressure,
        'Selected Pipe Pressure Maximum': pour.hydraulicSelectedSpecification?.maxPipePressure,
        'Selected Mass Concentration': pour.hydraulicSelectedSpecification?.massConcentration,
        'Selected Minimum Velocity': pour.hydraulicSelectedSpecification?.minVelocity,
        'Selected Settling Velocity': pour.hydraulicSelectedSpecification?.settlingVelocity,
        'Selected Flow Rate': pour.projectSpecificUnits[' Flow Rate'],
        'Selected Slurry Density': pour.projectSpecificUnits[' Slurry Density'],
        'Selected Solids Density': pour.projectSpecificUnits[' Solids Density'],
        'Selected Slurry Tonnage': pour.projectSpecificUnits[' Slurry Tonnage'],
        'Selected Slump': pour.projectSpecificUnits[' Slump'],
        Warnings: pour.warnings,
        'Rheology Data Set': rheologyDataSetReference,
        'Pour Completion Date': pour.pourData?.pourCompletedDate,
        'Pour Completion Dry Tonnage': pour.pourData?.dryTonnage,
        'Pour Completion Mass Concentration': pour.pourData?.massConcentration,
        'Pour Completion Binder Content': pour.pourData?.binderContent,
        'Pour Completion Volume': pour.pourData?.volume,
        'Pour Completion Total Cement': pour.pourData?.totalBinder,
        'Pour Completion Additional Comments': pour.pourData?.comments,
        'Pour Completion Binder Tailings Ratio': pour.pourData?.binderTailingsRatio,
        'Pour Completion Volume Retained After Outflow': pour.pourData?.volumeRetainedAfterOutflow,
      }));

      const t = [transforms.flatten({ objects: true })];
      const data = new Blob([new Parser({ transforms: t }).parse(fillType === FillType.Paste ? modifiedPasteReportData : modifiedHydraulicReportData)], { type: 'csv/txt' });

      setDownloadLink(window.URL.createObjectURL(data));
    }
  };

  React.useEffect(() => {
    processData();
  }, [pours]);

  return (
    <GridFooterContainer>
      <Link
        download={`${projectName ?? 'project'}-pour-data.csv`}
        href={downloadLink}
        type="text/csv"
        className={classes.link}
      >
        <Button size="small" startIcon={<CloudDownloadIcon />} color="secondary" variant="contained">Download Data</Button>
      </Link>
      <GridPagination />
    </GridFooterContainer>
  );
};

export default PoursTableFooter;
