import GuiState from './GuiState';
import ModelState from '../ModelState';

export default function resize(modelState: ModelState, guiState: GuiState, hglWidth?: number, hglHeight?: number, textScale?: number, margin?: number) {
  const currentGuiState = guiState;
  const { modelCanvas, hglCanvas, bufferCanvas, drawerWidth } = guiState;
  const { hglData } = modelState;

  currentGuiState.screenWidth = window.innerWidth;
  currentGuiState.screenHeight = window.innerHeight;
  modelCanvas.width = window.innerWidth;
  modelCanvas.height = window.innerHeight;
  hglCanvas.width = hglWidth ?? guiState.screenWidth / 6;
  hglCanvas.height = hglHeight ?? guiState.screenHeight / 4;
  const hglLeft = guiState.screenWidth - hglCanvas.width - drawerWidth;
  bufferCanvas.width = guiState.screenWidth;
  bufferCanvas.height = guiState.screenHeight;

  hglData.x = hglLeft;
  hglData.y = 135 + (textScale || 0);
  hglData.width = hglCanvas.width;
  hglData.height = hglCanvas.height;
  if (textScale) hglData.textScale = textScale;

  if (margin) hglData.xMargin += margin;
  if (margin) hglData.yMargin += margin;
  // HGLTelemetryItemList.x = guiState.screenWidth - hglCvs.width / 2 - 50 - drawerXOffset;
  // HGLTelemetryItemList.y = grHGL.y + grHGL.height + 50;
  // HGLTelemetryValueList.x = HGLTelemetryItemList.x + 50;
  // HGLTelemetryValueList.y = HGLTelemetryItemList.y;
  // const cx = canvas.width * 0.5;
  // const cy = canvas.height * 0.5;
  // centre = new Vector(cx, cy, 0);
  // const MARGIN = ((1 - GConstants.GUI_SCALE) / 2) * (guiState.screenWidth / 4);
  // tPos.x = (3 * guiState.screenWidth) / 4 + MARGIN;
  // tPos.y = MARGIN;

  // BUZZ: on resize the model disappears this fixes that
  // plot()
}
