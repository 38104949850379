import { pumpPressureKpaToBar } from 'utils/unitConversions';
import { CustomUnitFields } from 'providers/api';
import { RecipeState, Telemetry } from '../../typesInterfaces';
import {
  convertDensity, convertFlowRate,
  convertPumpPressure,
  convertTonnage
} from './conversions';

export default function updateHGLTelemetry(
  recipeState: RecipeState,
  imperial: boolean,
  customUnitFields: CustomUnitFields[],
) {
  const hglTelemetry: Telemetry = [];
  if (
    recipeState.wetFlowRate
    && recipeState.tailingsDryTonnage
    && recipeState.wetDensity
  ) {
    if (recipeState.pumpPressureKpa) hglTelemetry.push(['Pump Pressure:', convertPumpPressure(pumpPressureKpaToBar(recipeState.pumpPressureKpa), imperial, customUnitFields)]);
    hglTelemetry.push(['Slurry Density:', convertDensity(recipeState.wetDensity, imperial, customUnitFields)]);
    hglTelemetry.push(['Tailings Dry Tonnage:', convertTonnage(recipeState.tailingsDryTonnage, imperial, customUnitFields)]);
    hglTelemetry.push(['Wet Flow Rate:', convertFlowRate(recipeState.wetFlowRate, imperial, customUnitFields)]);
    if (Array.isArray(recipeState.boosterPumps)) {
      recipeState.boosterPumps.forEach((boosterPump) => {
        if (boosterPump.pressureKpa > 0) {
          hglTelemetry.push([
            `${boosterPump.name} Pressure:`,
            convertPumpPressure(pumpPressureKpaToBar(boosterPump.pressureKpa), imperial, customUnitFields),
          ]);
        }
      });
    }
  }
  return hglTelemetry;
}

// export default function updateHGLTelemetry(
//   guiState: GuiState,
//   ) {
//   const { unitSystemPreference, modelState: { recipeState } } = guiState;
//   let text: string[] = [];
//   if (
//     recipeState.wetFlowRate
//     && recipeState.dryTonnage
//     && recipeState.wetDensity
//     && recipeState.pumpPressure
//   ) {
//   unitSystemPreference === 5
//   ? text = [
//     `${round(recipeState.wetFlowRate, 0)} ${unitLabels[DisplayUnits.FlowRate].metric.abbreviation}`,
//     `${round(recipeState.dryTonnage, 0)} ${unitLabels[BasicDisplayUnits.Tonnage].metric.abbreviation}`,
//     `${round(recipeState.wetDensity, 0)} ${unitLabels[BasicDisplayUnits.Density].metric.abbreviation}`,
//     `${Number.isNaN(recipeState.pumpPressure) ? '-' : round(recipeState.pumpPressure, 0)} ${unitLabels[BasicDisplayUnits.Pressure].metric.abbreviation}`,
//   ]
//   : text = [
//     `${round(flowRateMetricToImperial(recipeState.wetFlowRate), 2)} ${unitLabels[DisplayUnits.FlowRate].imperial.abbreviation}`,
//     `${round(tonnageMetricToImperial(recipeState.dryTonnage), 0)} ${unitLabels[BasicDisplayUnits.Tonnage].imperial.abbreviation}`,
//     `${round(densityMetricToImperial(recipeState.wetDensity), 3)} ${unitLabels[BasicDisplayUnits.Density].imperial.abbreviation}`,
//     `${Number.isNaN(recipeState.pumpPressure) ? '-' : round(pressureMetricToImperial(recipeState.pumpPressure), 0)}
//     ${unitLabels[BasicDisplayUnits.Pressure].imperial.abbreviation}`,
//   ];
//   }
//   return text;
// }
