import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import AlertText from 'components/AlertText';
import {
  MineModelDto,
  ModelNodeDto,
  ProjectDto,
  RheologyDataDto,
  StopeBackfillSummaryDto,
  StopeLocationDto
} from 'providers/api';
import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { MineModelApi } from '../canvas/cmodel';
import PoursTabPanel from './PoursTab/PoursTabPanel';
import RecipesTabPanel from './RecipesTab/RecipesTabPanel';
import StopeTitleCard from './StopeTitleCard';

interface StopePanelPageProps {
  project: ProjectDto;
  mineModel: MineModelDto;
  mineOverview: StopeBackfillSummaryDto[];
  mineModelApi: MineModelApi | null;
  selectedRouteSpoolIds: string[];
  initialRheologyDataset?: RheologyDataDto;
  onStopeEdit: (nodeId: string) => void;
  nodeId: string | undefined;
}

type StopePanelTab = 'recipes' | 'pours';

const StopePanelPage = ({
  project,
  mineModel,
  mineOverview,
  mineModelApi,
  selectedRouteSpoolIds,
  initialRheologyDataset,
  onStopeEdit,
  nodeId,
}: StopePanelPageProps) => {
  const params = useParams<{ stopeId: StopeLocationDto['stopeId'], tab: string }>();
  const history = useHistory();
  const location = useLocation();

  const [tabValue, setTabValue] = React.useState<StopePanelTab>(params.tab as StopePanelTab ?? 'recipes');
  const [stopeSelected, setStopeSelected] = React.useState(false);

  const isStope = mineModel.reticulationData.stopeLocations.find((sl) => sl.nodeId.toString() === nodeId);
  const stope = mineModel.reticulationData.stopeLocations.find((n) => n.stopeId === params.stopeId);
  const stopeOverview = isStope ? mineOverview.find((summary) => summary.stopeIdentifier.stopeId === stope?.stopeId) : null;
  const stopeData = isStope ? mineModel.reticulationData.stopes.find((data) => data.stopeId === stope?.stopeId) : null;
  const modelState = mineModelApi?.getModelState();
  const backFillPlantNodes = modelState?.originNodes
  ?.map((x) => mineModel.reticulationData.nodes.find((a) => a.nodeId === x.id))
  .filter((node): node is ModelNodeDto => node !== undefined) || [];
  const activeBackFillPlantNode = mineModel.reticulationData.nodes.find((x) => x.nodeId === modelState?.activeBackfillPlant?.id);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: StopePanelTab) => {
    setTabValue(newValue);
    history.replace(location.pathname.replace(/[^/]*$/, newValue));
  };

  React.useEffect(() => {
    if (mineModelApi && stope && !stopeSelected) {
      mineModelApi.triggerSelectStope(stope.nodeId);
      setStopeSelected(true);
    }
    return () => {
      mineModelApi?.triggerUnselectStope();
      setStopeSelected(false);
    };
  }, [mineModelApi]);

  return (
    <>
      <Box>
        <StopeTitleCard
          stopeLocation={isStope ? stope : undefined}
          stopeName={stopeData?.stopeName ?? ''}
          hasIncompletePours={!!stopeOverview?.nextPourDate}
          stopeComplete={!!stopeOverview?.isComplete}
          completedVolume={stopeOverview?.completedVolume ?? 0}
          plannedVolume={stopeOverview?.plannedVolume ?? 0}
          volume={stopeData?.volume}
          onStopeEdit={onStopeEdit}
          onDeleteSuccess={mineModelApi?.deleteStope}
          unitSystemPreference={project.unitSystemPreference}
          customUnitFields={project.customUnitFields ?? []}
          nodeId={nodeId ?? ''}
        />
      </Box>
      <>
        {isStope && !stopeData && <AlertText severity="info">No data has been uploaded for this stope</AlertText>}
        {isStope && (
        <>
          <Box py={2}>
            <Paper square>
              <Tabs
                variant="fullWidth"
                value={tabValue}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleTabChange}
                aria-label="stope menu"
              >
                <Tab value="recipes" label="Recipes" />
                <Tab value="pours" label="Pours" />
              </Tabs>
            </Paper>
          </Box>
          {tabValue === 'pours' ? (
            <PoursTabPanel
              projectId={project.entityId}
              stopeId={stope?.stopeId || ''}
              displayUnitPreferences={project.displayUnitPreferences ?? []}
              customUnitFields={project.customUnitFields ?? []}
              unitSystemPreference={project.unitSystemPreference}
              fillType={project.fillType}
              throughputControlType={project.throughput}
              mineModelApi={mineModelApi}
              initialRheologyDataset={initialRheologyDataset}
              nodeId={nodeId ?? ''}
              backFillPlantNodes={backFillPlantNodes}
            />
       ) : (
         <RecipesTabPanel
           selectedRouteSpoolIds={selectedRouteSpoolIds}
           mineModelApi={mineModelApi}
           projectId={project.entityId}
           rheologyDatasetList={mineModel.rheologyDataList ?? []}
           displayUnitPreferences={project.displayUnitPreferences ?? []}
           fillType={project.fillType}
           throughputControlType={project.throughput}
           unitSystemPreference={project.unitSystemPreference}
           stopeId={stope?.stopeId || ''}
           stopeName={stopeData?.stopeName ?? ''}
           stopeComplete={stopeOverview?.isComplete ?? false}
           initialRheologyDataset={initialRheologyDataset}
           customUnitFields={project.customUnitFields}
           backFillPlantNodes={backFillPlantNodes}
           activeBackFillPlantNode={activeBackFillPlantNode}
         />
       )}
        </>
    )}
      </>
    </>
  );
};

export default StopePanelPage;
