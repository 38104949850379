import { isNumber } from 'utils';
import ModelState from '../Model/ModelState';
import updateFriction from '../Model/updateFriction';
import { updateRecipeState, verifyRecipeWithinDictionaryRange } from '../Model/updateRecipeState';
import { binderFromWaterBinderRatioAndBinder, roundUpBinderConc, waterBinderRatioFromConcentrationAndBinder } from './PCOtherFunctions';
import { calculateUCSfromWCandDays, calculateWCfromUCSandDays } from './ucsFunctions';
import {
  OptimisationErrorType,
  PasteOptimiseResult,
  RoutePoint,
  BoosterPumps
} from '../typesInterfaces';
import PCRoute from '../DiagraExtended/PCRoute';

function getPointsWithBoosterPressure(selectedRoute: PCRoute | undefined) {
  if (!selectedRoute || !Array.isArray(selectedRoute.routePoints)) {
      return [];
  }

  return selectedRoute.routePoints
      .filter((routePoint) => routePoint.point && routePoint.point.boosterPressure > 0)
      .map((routePoint) => routePoint);
}

function updateBoosterPumpPressures(
  boosterNodes: RoutePoint[],
  boosterPumpsAll: BoosterPumps[],
) {
  boosterNodes.forEach((routePointBooster) => {
    if (routePointBooster.pressure !== undefined) {
      // Find the corresponding boosterPump by name
      const matchingBoosterPump = boosterPumpsAll.find(
        (pump) => pump.name === routePointBooster.point.pointId,
      );
      // If a matching boosterPump is found, update its pressureKpa
      if (matchingBoosterPump) {
        matchingBoosterPump.pressureKpa = routePointBooster.pressure;
      }
    }
  });
}

const optimise = (
  _modelState: ModelState,
  waterBinderRatio: number,
  _maxPumpPressureBar: number,
   // Rheology
   newTailingsSolidsDensity?: number,
   newBinderParticleDensity?: number,
   newCarrierFluidDensity?: number,
   // throughput
   newTailingsDryTonnage?: number,
   newWetFlowRate?: number,
   newWetTonnage?: number,
): PasteOptimiseResult => {
  // Its difficult to optimise for 0 pump pressure as the model will almost always return a number "slightly" above 0, so we allow for an almost 0 value
  const maxPumpPressureKpa = (_maxPumpPressureBar === 0 ? 0.5 : _maxPumpPressureBar) * 100;
  const { fluid, selectedRoute, recipeState } = _modelState;
  const modelState = _modelState;
  const { binderRange, concentrationRange } = fluid;
  const boosterNodes = getPointsWithBoosterPressure(selectedRoute);
  let count = 0;
  let tempRecipeState = { ...recipeState };
  const result: PasteOptimiseResult = {
    cement: 0,
    concentrationByMass: 0,
    actualUCSKpa: 0,
    actualPumpPressureKpa: 0,
    error: OptimisationErrorType.OTHER,
  };
  let binderConcentrationByMass: number | undefined;

  // Returns false if something goes wrong and we are not able to optimise the model
  // TODO: Rounding up values to ensure UCS above requirement
  for (let currentMassConcentration = concentrationRange.max;
    currentMassConcentration >= concentrationRange.min;
    currentMassConcentration -= concentrationRange.interval
  ) {
    // mass conc range of values (from dictionary)

    // for each mass concentration, find the binder concentration that ensures the UCS condition is met.
    // This is rounded up to the nearest higher concentration value in the dictionary
    const tempBinderConcentrationByMass = binderFromWaterBinderRatioAndBinder(waterBinderRatio, currentMassConcentration); // Buzz: (Alcwyn) - 0 if func not available
    binderConcentrationByMass = roundUpBinderConc(tempBinderConcentrationByMass, binderRange);
    // the resulting binder concentration may be outside allowable limits and
    // therefore has no result in a friction dictionary lookup..
    // skip this entry
    if (binderConcentrationByMass >= binderRange.min && binderConcentrationByMass <= binderRange.max) {
      tempRecipeState = updateRecipeState(
        tempRecipeState,
        currentMassConcentration,
        binderConcentrationByMass,
        newTailingsSolidsDensity,
        newBinderParticleDensity,
        newCarrierFluidDensity,
        newTailingsDryTonnage,
        newWetFlowRate,
        newWetTonnage,
      );
      modelState.recipeState = tempRecipeState;
      updateFriction(modelState);
      if (selectedRoute && isNumber(selectedRoute.pumpPressureKpa)) {
        tempRecipeState.pumpPressureKpa = selectedRoute.pumpPressureKpa;
      }

      // Update Booster Nodes pressure on the recipe state with values from the selected route
      if (selectedRoute && Array.isArray(tempRecipeState.boosterPumps)) {
        updateBoosterPumpPressures(boosterNodes, tempRecipeState.boosterPumps);
      }

      count += 1;
      // checks for warnings, Overpressure and Overpressure Possible
      const routeOverpressure = selectedRoute?.warnings.some((warning) => warning.code === 'OP');
      const routeOverpressurePossible = selectedRoute?.warnings.some((warning) => warning.code === 'OPP');
      // check current mass conc selection for pump pressure, binder concentration and Overpressure warnings
      if (
        isNumber(modelState.recipeState.pumpPressureKpa)
        && isNumber(modelState.recipeState.binderConcentrationByMass)
        && modelState.recipeState.pumpPressureKpa < maxPumpPressureKpa
        && boosterNodes.every((node) => node.pressure! < node.point.boosterPressure * 100)
      ) {
        if (modelState.recipeState.binderConcentrationByMass <= binderRange.max) {
          if (routeOverpressure === false && routeOverpressurePossible === false) {
            modelState.recipeState = tempRecipeState;
            result.actualPumpPressureKpa = modelState.recipeState.pumpPressureKpa!;
            result.cement = modelState.recipeState.binderConcentrationByMass!;
            result.concentrationByMass = modelState.recipeState.totalDryConcentrationByMass!;
            result.error = OptimisationErrorType.NONE;
            return result;
          }
        }
      }
      if (count > 300) {
        result.error = OptimisationErrorType.OTHER;
      }
    }
  }
  if (binderConcentrationByMass! < binderRange.min) {
    result.error = OptimisationErrorType.UCS_TOO_LOW;
  }
  if (binderConcentrationByMass! > binderRange.max) {
    result.error = OptimisationErrorType.UCS_TOO_HIGH;
  }
  return result;
};

export const pasteRecipeFrS = (
  modelState: ModelState,
  pumpPressureBar: number, // in bar
  ucsIn: number,
  daysIn: number,
   // Rheology
  newTailingsSolidsDensity?: number,
  newBinderParticleDensity?: number,
  newCarrierFluidDensity?: number,
  // throughput
  newTailingsDryTonnage?: number,
  newWetFlowRate?: number,
  newWetTonnage?: number,
): PasteOptimiseResult => {
  const { fluid, recipeState } = modelState;
  const days = daysIn === 0 ? Object.keys(fluid.ucsCoefficients ?? {})[0] : daysIn;
  const WCUCSCoefficient1 = (fluid.ucsCoefficients as any)[days as number].coefficient1;
  const WCUCSCoefficient2 = (fluid.ucsCoefficients as any)[days as number].coefficient2;

  let result: PasteOptimiseResult = {
    cement: 0,
    concentrationByMass: 0,
    actualUCSKpa: 0,
    actualPumpPressureKpa: 0,
    error: OptimisationErrorType.OTHER,
  };

  recipeState.curingDuration = daysIn;
  if (WCUCSCoefficient1 && WCUCSCoefficient2) {
    const waterBinderRatio = calculateWCfromUCSandDays(
      ucsIn, WCUCSCoefficient1, WCUCSCoefficient2,
    );
    result = optimise(
      modelState,
      waterBinderRatio,
      pumpPressureBar,
      newTailingsSolidsDensity,
      newBinderParticleDensity,
      newCarrierFluidDensity,
      newTailingsDryTonnage,
      newWetFlowRate,
      newWetTonnage,
    );

    // w:C and pump pressure returns [%cement, %m], updates pipe friction
    // update with actual WC and UCS
    if (recipeState.totalDryConcentrationByMass && recipeState.binderConcentrationByMass && recipeState.curingDuration) {
      const finalWC = waterBinderRatioFromConcentrationAndBinder(recipeState.totalDryConcentrationByMass, recipeState.binderConcentrationByMass);
      const finalUCS = calculateUCSfromWCandDays(finalWC, WCUCSCoefficient1, WCUCSCoefficient2);
      recipeState.unconfinedCompressiveStrengthKpa = finalUCS;
      result.actualUCSKpa = finalUCS;
    }
  }
  return result;
};

// Buzz (Alcwyn): add param inputs from react.
export function pasteStrengthFrR(
  modelState: ModelState,
  dryConcentrationByMass: number,
  binderConcentration: number,
  // From rheology data
  newTailingsSolidsDensity?: number,
  newBinderParticleDensity?: number,
  newCarrierFluidDensity?: number,

  newTailingsDryTonnage?: number,
  newWetFlowRate?: number,
  newWetTonnage?: number,
) {
  const { recipeState, fluid, selectedRoute, dictionaryRange } = modelState;
  const { curingDuration } = modelState.recipeState;
  if (typeof curingDuration !== 'undefined') {
    const { coefficient1, coefficient2 } = curingDuration === 0 ? Object.values(fluid.ucsCoefficients)[0] : (fluid.ucsCoefficients as any)[curingDuration];
    if (typeof coefficient1 !== 'undefined' && typeof coefficient2 !== 'undefined') {
      const waterBinderRatio = waterBinderRatioFromConcentrationAndBinder(dryConcentrationByMass, binderConcentration);
      const calculatedUCS = calculateUCSfromWCandDays(waterBinderRatio, coefficient1, coefficient2);
      recipeState.unconfinedCompressiveStrengthKpa = parseInt(calculatedUCS.toPrecision(calculatedUCS > 9999 ? 5 : 4), 10);
      modelState.setRecipeState(updateRecipeState(
        recipeState,
        dryConcentrationByMass,
        binderConcentration,
        newTailingsSolidsDensity,
        newBinderParticleDensity,
        newCarrierFluidDensity,
        newTailingsDryTonnage,
        newWetFlowRate,
        newWetTonnage,
      ));
      const dictionaryCheck = verifyRecipeWithinDictionaryRange(recipeState, dictionaryRange);
      console.log(dictionaryCheck);
      updateFriction(modelState);
      modelState.setPumpPressure(selectedRoute?.pumpPressureKpa as number);
      const boosterNodes = getPointsWithBoosterPressure(selectedRoute);
      updateBoosterPumpPressures(boosterNodes, modelState.recipeState.boosterPumps!);
    }
  }
}
