import PCNode from './DiagraExtended/PCNode';
import Vector from './Diagra/Vector';

export function vectorInDOMRect(rect: any, v: Vector) {
  if (typeof v !== 'undefined') {
    if (v.x > rect.x
    && v.x < (rect.x + rect.width)
    && v.y > rect.y
    && v.y < (rect.y + rect.height)
    ) return true;
  }
  return false;
}
// Converted from the arrow functions
  // const checkArray = (n: PCNode, a: PCNode[]) => a && a.find((node) => node === n);
  // const returnIndex = (n: PCNode, a: PCNode[]) => a.findIndex((node) => node === n);

// returns node if node in array
export function checkArrayForNode(n: PCNode, a: PCNode[]) {
  let foundNode;
  if (a) {
    foundNode = a.find((node) => node === n);
  }
  return foundNode;
}

// returns index of node in array
export function returnIndex(n: PCNode, a: PCNode[]) {
  return a.findIndex((node) => node === n);
}

export function minmaxList(l: any) {
  // searches list and returns maximum and minimum values and indices
  Math.max.apply(false, l);
  let min = [l[0], 0];
  let max = [l[0], 0];
  for (let i: number = 0; i < l.length; i += 1) {
    if (l[i] < min[0]) min = [l[i], i];
    if (l[i] > max[0]) max = [l[i], i];
  }

  return [min, max];
}

export function convertSpoolsToRoutePointList(spoolIds: string[]) {
  const uniqueValues = new Set();

  spoolIds.forEach((item) => {
    if (item.includes('-')) {
      // Split on "-" and add both parts to the set
      const [part1, part2] = item.split('-');
      uniqueValues.add(part1.trim());
      uniqueValues.add(part2.trim());
    } else {
      // Add the whole item directly to the set
      uniqueValues.add(item.trim());
    }
  });

  // Convert the set to a sorted array (optional)
  return Array.from(uniqueValues);
}
