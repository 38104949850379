import {
  BackfillWarningDto,
  CustomUnitFields,
  FillType,
  RheologyDataDto,
  StopeDataDto,
  StopeLocationDto,
  ThroughputControlType,
  UnitSystem
} from 'providers/api';
import Vector from './Diagra/Vector';
import initialModelView from './Model/initialModelView';
import updateFriction from './Model/updateFriction';
import { updateRecipeState } from './Model/updateRecipeState';
import { binderPercentFromRatio, hydraulicRecipeFrS, hydraulicStrengthFrR } from './PCFunctions/PCHydraulicFillFunctions';
import { pasteRecipeFrS, pasteStrengthFrR } from './PCFunctions/PCPasteFunctions';
import { calculateWCfromUCSandDays } from './PCFunctions/ucsFunctions';
import {
  DictionaryRange,
  HydraulicCalculateResult,
  HydraulicOptimiseResult,
  PasteAdjustedResult,
  PasteOptimiseResult
} from './typesInterfaces';
// import updateHGLTelemetry from './Model/Telemetry/updateHglTelemetry';
import { loadFluid, setupModel } from './Model/setupModel';
// import { plotHGL, clearPlots } from './Model/hgl/plotHGL';
import GuiState from './Model/GUI/GuiState';
import { canvasPrecedence } from './Model/GUI/guiMouse';
import resize from './Model/GUI/resize';
import setupGui from './Model/GUI/setupGUI';
import ModelState from './Model/ModelState';
import { plotNodeStopeTelemetry } from './Model/Telemetry/plotTelemetry';
import updateHGLTelemetry from './Model/Telemetry/updateHglTelemetry';
import plotHGL from './Model/hgl/plotHGL';
import plotPipeColorKey from './Model/pipeColorKey/pipeColorKey';
import plotModel from './Model/plotModel';
import { getPumpPressure } from './PCFunctions/PCOtherFunctions';

declare global {
  interface CanvasRenderingContext2D {
    roundRect: (x: number, y: number, w: number, h: number, r: number) => CanvasRenderingContext2D;
  }
}
export interface MineModelApi {
  limits: DictionaryRange;
  getPumpPressure: () => number | undefined;
  selectPan: () => void;
  selectRotate: () => void;
  selectAddStope: () => void;
  selectBackfillPlant: () => void;
  selectShowPipeTypes: () => void;
  selectFocusRoute: () => void;
  selectReset: () => void;
  triggerSelectStope: (nodeId: string) => void;
  triggerUnselectStope: () => void;
  hoverNode: (nodeId?: string) => void;
  addStope: (stopeLocation: StopeLocationDto, newStope: StopeDataDto, isPit: boolean, isFrictionLoop: boolean) => void;
  updateStope: (stopeLocation: StopeLocationDto, newStope: StopeDataDto) => void;
  deleteStope: (nodeId: string) => void;
  setMainMenuWidth: (offset: number) => void;
  setDrawerWidth: (offset: number) => void;
  triggerPasteRecipeFromStrength: (
    ucsStrength: number,
    curingPeriod: number,
    pumpPressureBar: number,
    newTailingsSolidsDensity?: number,
    newBinderParticleDensity?: number,
    newCarrierFluidDensity?: number,
    newTailingsDryTonnage?: number,
    newWetFlowRate?: number,
    newWetTonnage?: number,
  ) => PasteOptimiseResult;
  triggerHydraulicFillRecipeFromStrength: (
    binderTailings: number,
    dryTonsIn?: number,
    flowRateIn?: number,
    wetTonsIn?: number
  ) => HydraulicOptimiseResult;
  triggerPasteStrengthAndPressureFromRecipe: (concentrationByMass: number, cement: number) => PasteAdjustedResult;
  triggerHydraulicFillStrengthFromRecipe: (
    binderTailings: number,
    concentrationByMass: number,
    newTailingsSolidsDensity? : number,
    newBinderParticleDensity?: number,
    newCarrierFluidDensity?: number,
    tailingsDryTonnage?: number,
    wetFlowRate?: number,
    wetTonnage?: number,
  ) => HydraulicCalculateResult;
  triggerSendPasteRecipe: (
    unconfinedCompressiveStrength: number,
    curingDuration: number,
    drySolidsConcentrationByMass: number,
    binderConcentration: number,
    pumpPressureBar: number,
    newTailingsSolidsDensity?: number,
    newBinderParticleDensity?: number,
    newCarrierFluidDensity?: number,
    tailingsDryTonnage?: number,
    wetFlowRate?: number,
    wetTonnage?: number,
  ) => void;
  triggerSendHydraulicRecipe: (
    binderTailingsRatio: number,
    drySolidsConcentrationByMass: number,
    newTailingsSolidsDensity?: number,
    newBinderParticleDensity?: number,
    newCarrierFluidDensity?: number,
    tailingsDryTonnage?: number,
    wetFlowRate?: number,
    wetTonnage?: number,
  ) => void;
  triggerClearPlots: () => void;
  calculateWCfromUCSandDays: (UCS: number, c1: number, c2: number) => number;
  updateSelectedRheologyData: (newRheologyData: RheologyDataDto | undefined, throughputControlType: ThroughputControlType) => DictionaryRange | undefined;
  checkWarningsForSelectedRoute: () => BackfillWarningDto[],
  setSelectedRouteFromSpoolIds: (spoolIds: string[] | undefined) => void, // Spool Ids are a concatenation of node Ids delimited by '-'
  getModelState: () => ModelState;
  deselectRecipe: () => void;
  selectModelCanvas: () => void;
  plotBackfillPlantColorKey: (nodeId: string) => void;
  hglZoomIn: () => void;
  hglZoomOut: () => void;
}

const initialiseCanvas = (
  cv: HTMLCanvasElement,
  onStopeSelect: (stopeId: string) => void,
  returnSelectedRoute: (spoolIds: string[]) => void, // array of spool Ids
  mainMenuWidth: number,
  drawerWidth: number,
  data: any, // INFO: Could be a MineModelDto to improve type safety but creates errors that would need resolving.
  unitSystemPreference: UnitSystem,
  fillType: FillType,
  initialRheologyDataset: RheologyDataDto,
  throughputControlType: ThroughputControlType,
  customUnitFields: CustomUnitFields[],
  defaultBackfillPlantNodeId: string,
  onErrorShow: (err: Error)=> void,
): MineModelApi => {
  // TODO: is there a better way of setting up the canvas sizes?

  window.localStorage.viewZoom = 1;

  let modelState: ModelState = setupModel(data, onStopeSelect, returnSelectedRoute, fillType,
    initialRheologyDataset, throughputControlType, defaultBackfillPlantNodeId, onErrorShow);
  let guiState: GuiState = setupGui(cv, drawerWidth, unitSystemPreference, mainMenuWidth, modelState, customUnitFields);

  const { modelCanvas } = guiState;

  const plot = () => {
    plotModel(guiState);
    plotHGL(guiState);
    plotPipeColorKey(guiState);
    plotNodeStopeTelemetry(guiState);
  };

  const triggerPasteRecipeFromStrength = (
    ucsStrength: number,
    curingPeriod: number,
    pumpPressureBar: number,
    // Rheology
    newTailingsSolidsDensity?: number,
    newBinderParticleDensity?: number,
    newCarrierFluidDensity?: number,
    // throughput
    newTailingsDryTonnage?: number,
    newWetFlowRate?: number,
    newWetTonnage?: number,

  ): PasteOptimiseResult => {
    const optimisationResult = pasteRecipeFrS(
      modelState,
      pumpPressureBar,
      ucsStrength,
      curingPeriod,
      newTailingsSolidsDensity,
      newBinderParticleDensity,
      newCarrierFluidDensity,
      newTailingsDryTonnage,
      newWetFlowRate,
      newWetTonnage,
    );
    guiState.updateLegend();
    modelState.hglTelemetry = updateHGLTelemetry(modelState.recipeState, guiState.unitSystemPreference === 10, customUnitFields);
    plot();
    return optimisationResult;
  };

  function triggerHydraulicFillRecipeFromStrength(
    binderTailingsRatio: number,
    tailingsDryTonnage?: number,
    wetFlowRate?: number,
    wetTonnage?: number,
  ) {
    hydraulicRecipeFrS(
      modelState,
      binderTailingsRatio,
      undefined,
      undefined,
      undefined,
      tailingsDryTonnage,
      wetFlowRate,
      wetTonnage,
    );
    guiState.updateLegend();
    const { recipeState } = modelState;
    const { totalDryConcentrationByMass } = recipeState;
    guiState.activeRecipeStatus = true;
    modelState.hglTelemetry = updateHGLTelemetry(modelState.recipeState, guiState.unitSystemPreference === 10, customUnitFields);
    if (totalDryConcentrationByMass) {
      return { concentrationByMass: totalDryConcentrationByMass * 100 } as HydraulicOptimiseResult;
    }
    // TOTO: update UCS
    return { concentrationByMass: 0 } as HydraulicOptimiseResult;
  }

  function triggerPasteStrengthAndPressureFromRecipe(
    dryConcentrationByMass: number,
    binderConcentration: number,
    // From rheology data
    newTailingsSolidsDensity?: number,
    newBinderParticleDensity?: number,
    newCarrierFluidDensity?: number,
    // Throughput
    newTailingsDryTonnage?: number,
    newWetFlowRate?: number,
    newWetTonnage?: number,
  ): PasteAdjustedResult {
    const { selectedRoute } = modelState;
    let dConcentrationByMass = dryConcentrationByMass;
    if (dConcentrationByMass > 1) (dConcentrationByMass /= 100).toPrecision(2);
    let bConcentration = binderConcentration;
    if (bConcentration > 1) (bConcentration /= 100).toPrecision(2);
    if (selectedRoute !== null) {
      pasteStrengthFrR(
        modelState,
        dConcentrationByMass,
        bConcentration,
        newTailingsSolidsDensity,
        newBinderParticleDensity,
        newCarrierFluidDensity,
        newTailingsDryTonnage,
        newWetFlowRate,
        newWetTonnage,
      );
      guiState.updateLegend();
      guiState.activeRecipeStatus = true;
      modelState.hglTelemetry = updateHGLTelemetry(modelState.recipeState, guiState.unitSystemPreference === 10, customUnitFields);
      plot();

      return {
        actualUCSKpa: modelState.recipeState.unconfinedCompressiveStrengthKpa ?? 0,
        actualPumpPressureKpa: modelState.recipeState.pumpPressureKpa ?? 0,
      };
    }

    return {
      actualUCSKpa: 0,
      actualPumpPressureKpa: 0,
    };
  }

  function triggerHydraulicFillStrengthFromRecipe(
    binderTailingsRatio: number,
    dryConcentrationByMass: number,
    // From rheology
    newTailingsSolidsDensity?: number,
    newBinderParticleDensity?: number,
    newCarrierFluidDensity?: number,

    // depends on throughputControlType
    tailingsDryTonnage?: number,
    wetFlowRate?: number,
    wetTonnage?: number,
  ) {
    let dConcentrationByMass = dryConcentrationByMass;
    if (dConcentrationByMass > 1) dConcentrationByMass /= 100;
    const output = hydraulicStrengthFrR(
      modelState,
      binderTailingsRatio,
      dConcentrationByMass,
      newTailingsSolidsDensity,
      newBinderParticleDensity,
      newCarrierFluidDensity,
      tailingsDryTonnage,
      wetFlowRate,
      wetTonnage,
    );
    guiState.updateLegend();
    guiState.activeRecipeStatus = true;
    modelState.recipeState = output.recipeState;
    modelState.hglTelemetry = updateHGLTelemetry(modelState.recipeState, guiState.unitSystemPreference === 10, customUnitFields);
    plot();
    return output.hydraulicCalculateResult;
  }

  function triggerSendPasteRecipe(
    // Triggers when existing recipe selected in sidebar
    // a stope must also be selected
    // avoids re-optimisation by returning previously optimised values.
    // From recipe
    unconfinedCompressiveStrength: number,
    curingDuration: number,
    drySolidsConcentrationByMass: number,
    binderConcentration: number,
    pumpPressureBar: number,

    // From rheology
    newTailingsSolidsDensity?: number,
    newBinderParticleDensity?: number,
    newCarrierFluidDensity?: number,

    // depends on throughputControlType
    tailingsDryTonnage?: number,
    wetFlowRate?: number,
    wetTonnage?: number,
  ) {
    let dConcentrationByMass = drySolidsConcentrationByMass;
    if (dConcentrationByMass > 1) dConcentrationByMass /= 100;
    let bConcentration = binderConcentration;
    if (bConcentration > 1) bConcentration /= 100;
    const { recipeState } = modelState;

    // clear the pump pressure to ensure it does not appear in the HGL
    recipeState.pumpPressureKpa = pumpPressureBar * 100;

    modelState.setRecipeState(updateRecipeState(
      recipeState,
      dConcentrationByMass,
      bConcentration,
      newTailingsSolidsDensity,
      newBinderParticleDensity,
      newCarrierFluidDensity,
      tailingsDryTonnage,
      wetFlowRate,
      wetTonnage,
    ));
    guiState.updateLegend();
    guiState.activeRecipeStatus = true;
    recipeState.unconfinedCompressiveStrengthKpa = unconfinedCompressiveStrength;
    recipeState.curingDuration = curingDuration;
    updateFriction(modelState);
    modelState.hglTelemetry = updateHGLTelemetry(modelState.recipeState, guiState.unitSystemPreference === 10, customUnitFields);
    plot();
  }

  function triggerSendHydraulicRecipe(
    binderTailingsRatio: number,
    drySolidsConcentrationByMass: number,

    // From rheology
    newTailingsSolidsDensity?: number,
    newBinderParticleDensity?: number,
    newCarrierFluidDensity?: number,

    tailingsDryTonnage?: number,
    wetFlowRate?: number,
    wetTonnage?: number,
  ) {
    // TODO: regenerate visuals for recipe
    console.log({ binderTailingsRatio, tailingsDryTonnage, drySolidsConcentrationByMass });
    let dConcentrationByMass = drySolidsConcentrationByMass;
    if (dConcentrationByMass > 1) dConcentrationByMass /= 100;
    const bConcentration = binderPercentFromRatio(binderTailingsRatio);
    const { recipeState } = modelState;
    modelState.setRecipeState(updateRecipeState(
      recipeState,
      dConcentrationByMass,
      bConcentration,
      // Rheology
      newTailingsSolidsDensity,
      newBinderParticleDensity,
      newCarrierFluidDensity,
      // throughput
      tailingsDryTonnage,
      wetFlowRate,
      wetTonnage,
    ));
    guiState.updateLegend();
    guiState.activeRecipeStatus = true;
    updateFriction(modelState);
    modelState.hglTelemetry = updateHGLTelemetry(modelState.recipeState, guiState.unitSystemPreference === 10, customUnitFields);
    plot();
  }

  function checkWarningsForSelectedRoute(): BackfillWarningDto[] {
    if (modelState.selectedRoute) {
      // Buzz (alcwyn): Reporting errors in the React app
      return modelState.selectedRoute.findWarnings();
      // TODO: renderWarnings
      // activeRoute.renderWarnings(bufferCtx);
    }
    return [];
  }

  function addStope(stopeLocation: StopeLocationDto, newStope: StopeDataDto, isPit: boolean, isFrictionLoop: boolean) {
    modelState.setNodeAsStope(stopeLocation.nodeId, isPit, isFrictionLoop);
    modelState.completeAddStopeWorkflow(stopeLocation, newStope);
    plot();
  }

  function updateStope(stopeLocation: StopeLocationDto, updatedStope: StopeDataDto) {
    modelState.updateStope(stopeLocation, updatedStope);
    // calling hoverNode as it refreshes the node telemetry
    guiState.hoverNode(stopeLocation.nodeId);
    plot();
  }

  function deleteStope(nodeId: string) {
    modelState.unsetNodeAsStope(nodeId);
    plot();
  }

  // Event Listeners
  const mouseEvt = (e: MouseEvent) => {
    canvasPrecedence(e, modelState, guiState);
    plot();
  };

  const doubleClick = () => { };

  const mouseWheel = (e: WheelEvent) => {
    const mousePos = new Vector(e.clientX, e.clientY, 0);
    const s = e.deltaY < 0 ? 1.08 : 0.92;
    Object.values(modelState.nodes).forEach((node) => {
      const n = node;
      n.screenVector.sub(mousePos);
      n.screenVector.scale(s);
      n.screenVector.add(mousePos);
    });
    window.localStorage.viewZoom *= s;
    // console.log(window.localStorage.viewZoom);
    // modelState.scale *= s;
    plot();
  };

  function selectPan() {
    guiState.triggerPan();
  }
  function selectRotate() {
    guiState.triggerRotate();
  }
  function selectReset() {
    modelState = setupModel(data, onStopeSelect, returnSelectedRoute, fillType, initialRheologyDataset,
       throughputControlType, defaultBackfillPlantNodeId, onErrorShow);
    guiState = setupGui(cv, drawerWidth, unitSystemPreference, mainMenuWidth, modelState, customUnitFields);
    resize(modelState, guiState);
    window.localStorage.viewZoom = 1;
    initialModelView(modelState, guiState);
    plot();
  }
  function selectAddStope() {
    guiState.triggerAddStope();
  }
  function selectBackfillPlant() {
    guiState.triggerBackfillPlant();
  }
  function selectShowPipeTypes() {
    guiState.triggerPipeTypes();
    plot();
  }
  function selectFocusRoute() {
    guiState.triggerIsolateRoute();
    plot();
  }

  function triggerResize() {
    resize(modelState, guiState);
    plot();
  }

  window.addEventListener('resize', triggerResize, false);
  // TODO: Pull in setup orientation from Retic file
  triggerResize();
  initialModelView(modelState, guiState);

  modelCanvas.addEventListener('mousemove', mouseEvt, false);
  modelCanvas.addEventListener('mousedown', mouseEvt, false);
  modelCanvas.addEventListener('mouseup', mouseEvt, false);
  modelCanvas.addEventListener('dblclick', doubleClick, false);
  modelCanvas.addEventListener('wheel', mouseWheel, false);
  modelCanvas.addEventListener('click', mouseEvt, false);

  plot();
  // Buzz (Alcwyn): Allow the graph positions to be updated based on whether the draw is open or not
  const setMainMenuWidth = (offset: number) => {
    guiState.mainMenuWidth = offset;
    plot();
  };

  const setDrawerWidth = (offset: number) => {
    guiState.drawerWidth = offset;
    triggerResize();
    plot();
  };

  const triggerSelectStope = (nodeId: string) => {
    console.log('trigger select stope');
    modelState.setSelectedNode(nodeId, false, guiState);
    plot();
  };

  const triggerUnselectStope = () => {
    modelState.unselectStope();
    guiState.unHoverNode();
  };

  const triggerGetPumpPressure = () => {
    if (modelState.selectedRoute && modelState.recipeState.wetDensity) {
      return getPumpPressure(modelState.selectedRoute);
    }
    return 0;
  };

  // TODO: Pat to check how rerender occurs for non-canvas based interaction.
  const hoverNode = (nodeId?: string) => {
    if (nodeId) {
      guiState.hoverNode(nodeId);
    }
  };

  const triggerClearPlots = () => {
    // clearPlots(modelState.hglData);
  };

  const updateSelectedRheologyData = (newRheologyData: RheologyDataDto | undefined): DictionaryRange | undefined => {
    if (newRheologyData) {
      const dictionaryRange = {
        concentration: newRheologyData.massConcentration,
        tons: newRheologyData.tonnage,
        cement: newRheologyData.binder,
        pumpPressure: {
          min: 0,
          max: newRheologyData.pumpPressure.max,
          interval: 1,
          default: newRheologyData.pumpPressure.default,
        },
            };
      modelState.fluid = loadFluid(newRheologyData, dictionaryRange, modelState.fluid.type);
      modelState.setRecipeState(updateRecipeState(
        modelState.recipeState,
        dictionaryRange.concentration.min,
        dictionaryRange.cement.min,
        modelState.fluid.drySolidsDensity,
        modelState.fluid.binderDryParticleDensity,
        modelState.fluid.carrierFluidDensity,
        throughputControlType === ThroughputControlType.DryTonnage ? dictionaryRange.tons.default : undefined,
        throughputControlType === ThroughputControlType.DryTonnage ? dictionaryRange.tons.default : undefined,
        throughputControlType === ThroughputControlType.DryTonnage ? dictionaryRange.tons.default : undefined,
      ));
      return dictionaryRange;
    }
    return undefined;
  };

  const setSelectedRouteFromSpoolIds = (spoolIds: string[] | undefined) => {
    if (!spoolIds?.length) {
      guiState.modelState.setSelectedRoute(undefined);
      return;
    }

    const originNodeId = spoolIds[spoolIds.length - 1];
    const originNode = modelState.originNodes?.find((node) => node.pointId === originNodeId);

    if (originNode) {
      modelState.setSelectedBackfillPlant(originNode, guiState);
    }

    guiState.modelState.selectRouteFromSpoolIds(spoolIds);
  };

  const getModelState = () => modelState;

  const deselectRecipe = () => {
    guiState.activeRecipeStatus = false;
    plot();
  };

  const selectModelCanvas = () => {
    modelCanvas.click();
  };

  const plotBackfillPlantColorKey = (nodeId: string) => {
    const originNode = modelState.originNodes?.find((x) => x.pointId === nodeId);
    if (originNode) {
      modelState.setSelectedBackfillPlant(originNode, guiState);
    }
  };

  const hglZoomIn = () => {
    const newWidth = guiState.hglCanvas.width + 25;
    const newHeight = guiState.hglCanvas.height + 25;
    const textScale = modelState.hglData.textScale + 0.8;
    resize(modelState, guiState, newWidth, newHeight, textScale, 3);
    modelState.hglData.refresh();
    selectModelCanvas();
};

const hglZoomOut = () => {
    const newWidth = guiState.hglCanvas.width - 25;
    const newHeight = guiState.hglCanvas.height - 25;
    const textScale = modelState.hglData.textScale - 0.8;
    resize(modelState, guiState, newWidth, newHeight, textScale, -3);
    modelState.hglData.refresh();
    selectModelCanvas();
};

  return {
    limits: modelState.dictionaryRange,
    getPumpPressure: triggerGetPumpPressure,
    selectPan,
    selectRotate,
    selectReset,
    selectAddStope,
    selectBackfillPlant,
    selectShowPipeTypes,
    selectFocusRoute,
    triggerSelectStope,
    triggerUnselectStope,
    hoverNode,
    addStope,
    updateStope,
    deleteStope,
    setMainMenuWidth,
    setDrawerWidth,
    triggerPasteRecipeFromStrength,
    triggerHydraulicFillRecipeFromStrength,
    triggerPasteStrengthAndPressureFromRecipe,
    triggerHydraulicFillStrengthFromRecipe,
    triggerSendPasteRecipe,
    triggerClearPlots,
    calculateWCfromUCSandDays,
    updateSelectedRheologyData,
    checkWarningsForSelectedRoute,
    setSelectedRouteFromSpoolIds,
    getModelState,
    triggerSendHydraulicRecipe,
    deselectRecipe,
    selectModelCanvas,
    plotBackfillPlantColorKey,
    hglZoomIn,
    hglZoomOut,
  };
};

export default initialiseCanvas;
