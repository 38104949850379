import Backdrop from '@material-ui/core/Backdrop';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import {
  createStyles,
  makeStyles
} from '@material-ui/core/styles';
import ReplayIcon from '@material-ui/icons//Replay';
import AdjustIcon from '@material-ui/icons/Adjust';
import LinearScaleIcon from '@material-ui/icons/LinearScale';
import PanToolIcon from '@material-ui/icons/OpenWith';
import ThreeDRotationIcon from '@material-ui/icons/ThreeDRotation';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import clsx from 'clsx';
import AlertText from 'components/AlertText';
import useMenuState from 'components/Layout/MenuStateProvider';
import { BasePageProps } from 'modules/makePage';
import ProjectAuthorise from 'modules/project/ProjectAuthorise';
import { ProjectProvider } from 'modules/project/providers/project';
import {
  CustomUnitFields,
  FillType,
  MineModelDto,
  ProjectDto,
  RheologyDataDto,
  StopeDataDto,
  StopeLocationDto, ThroughputControlType, UnitSystem, useRheologyDataQuery
} from 'providers/api';
import { Permission } from 'providers/authorisation';
import React, { useCallback, useRef } from 'react';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch
} from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { FormatColorFill } from '@material-ui/icons';
import useLocalStorage from '@rehooks/local-storage';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import { ProjectAreaQueries } from '../useProjectAreaQueries';
import Canvas from './Canvas';
import ModelDrawer from './ModelDrawer';
import ModelDrawerTrigger from './ModelDrawerTrigger';
import StopePanelPage from './StopePanelPage';
import StopesPanelPage from './StopesPanelPage';
import initialiseCanvas, { MineModelApi } from './canvas/cmodel';
import RotateCursor from './rotate-cursor.svg';
import useDeleteStope from './useDeleteStope';
import NodeForm from './NodeForm/NodeForm';

interface NodeResullt {
  stopeLocation?: StopeLocationDto;
  isPit?: boolean;
  isFrictionLoop?: boolean;
}

const useStyles = makeStyles(createStyles({
  canvas: {
    position: 'fixed',
    top: 0,
    left: 0,
  },
  panHover: {
    cursor: 'move',
  },
  rotate: {
    cursor: `url(${RotateCursor}), default`,
  },
}));

type VisualModelAction = 'pipes' | 'focus';
type ExclusiveModelAction = 'pan' | 'rotate' | 'stope' | 'remove-stope' | 'backfillPlant';
type FunctionMap = Record<string, Function>;

const MAIN_MENU_OPEN_WIDTH = 271;
const MAIN_MENU_CLOSED_WIDTH = 75;
const SIDE_PANEL_OPEN_WIDTH = 400;
const SIDE_PANEL_CLOSED_WIDTH = 0;

const ProjectStopesPage = ({ queries }: BasePageProps<ProjectAreaQueries>) => {
  const [
    projectQuery,
    mineModelQuery,
    mineOverviewQuery,
  ] = queries;

  const project = projectQuery?.data;
  const mineModel = mineModelQuery?.data;
  const mineOverview = mineOverviewQuery?.data;

  const BACKFILLPLANT_KEY_LOCALSTORAGE = `${project?.entityId}-backFillPlantNodeId`;

  const [drawerOpen, setDrawerOpen] = React.useState(true);
  const [activeExclusiveAction, setActiveExclusiveAction] = React.useState<ExclusiveModelAction | null>(null);
  const [activeVisualActions, setActiveVisualActions] = React.useState<VisualModelAction[] | null>([]);
  const [stopeIsSelected, setStopeIsSelected] = React.useState<boolean>(false);
  const [activeNodeId, setActiveNodeId] = React.useState<string | undefined>();
  const [nodeIdLoaded, setNodeIdLoaded] = React.useState<string | undefined>();
  const [selectedRouteSpoolIds, setSelectedRouteSpoolIds] = React.useState<string[]>([]);

  const [mineModelApi, setMineModelApi] = React.useState<MineModelApi | null>(null);

  const history = useHistory();
  const classes = useStyles();
  const { path, url } = useRouteMatch();
  const deleteStope = useDeleteStope();
  const menuState = useMenuState();
  const { enqueueSnackbar } = useSnackbar();
  // passing a string of number example: '67'. useLocalStorage is automatically convert into coerce the string into number. That lead to incorrect result
  const [defaultBackfillPlantNodeId, setDefaultBackFillPlantNodeId] = useLocalStorage<string>(BACKFILLPLANT_KEY_LOCALSTORAGE);

  // Experienced a stale closure When passing callbacks to the model. React documentation suggests using
  // a Ref for getting around this. This approach works despite how counter intuitive it seems to have a
  // a duplicate declaration. Leave in unless there is a better solution.
  const mineModelDataRef = React.useRef<MineModelDto>();
  const projectRef = React.useRef<ProjectDto>();
  const activeExclusiveActionRef = React.useRef<ExclusiveModelAction | null>();
  const exclusiveActionFunctionMap = React.useRef<FunctionMap | null>();
  const initialRheologyDatasetRef = React.useRef<RheologyDataDto>();
  const mineModelApiRef = React.useRef<MineModelApi>();
  const nodeNavigationRef = React.useRef<NodeResullt | null>(null);
  const debounceTimer = useRef<any | null>(null);

  if (mineModelApi) mineModelApiRef.current = mineModelApi;

  activeExclusiveActionRef.current = activeExclusiveAction;

  // load the first of the rheology data sets
  const { isLoading: initialRheologyDatasetIsLoading, data: initialRheologyDataset } = useRheologyDataQuery(
    {
      mineModelId: project?.entityId ?? '',
      rheologyDatasetId: mineModel?.rheologyDataList?.[0]?.rheologyDataSetId ?? '',
    },
    {
      enabled: !!(mineModel?.rheologyDataList?.[0]?.rheologyDataSetId),
    },
  );

  const handleExclusiveActionChange = (event: React.MouseEvent<HTMLElement>, nextAction: ExclusiveModelAction) => {
    setActiveExclusiveAction(nextAction);
  };

  const handleVisualActionChange = (event: React.MouseEvent<HTMLElement>, nextActions: VisualModelAction[]) => {
    setActiveVisualActions(nextActions);
  };

  const handleStopeNavigation = (stopeLocation: StopeLocationDto) => {
    // turn off the exclusive action when navigating to avoid the delete stope action being triggered
    setActiveExclusiveAction(null);

    setStopeIsSelected(true);
    history.push(`${url.replace(/\/$/, '')}/${stopeLocation.stopeId}/recipes`);
  };

  const handleDefaultNavigation = () => {
    history.push(`${url.replace(/\/$/, '')}`);
  };

  const handleRouteChange = (spoolList: string[]) => {
    setSelectedRouteSpoolIds(spoolList);
  };

  const handleEscapeKey = (event: KeyboardEvent) => {
    if (mineModelApi && event.key === 'Escape') {
      if (
        activeExclusiveActionRef.current
        && exclusiveActionFunctionMap.current
        && exclusiveActionFunctionMap.current[activeExclusiveActionRef.current]
      ) {
        exclusiveActionFunctionMap.current[activeExclusiveActionRef.current]();
        setActiveExclusiveAction(null);
      }
    }
  };

  // Callback from model
  const handleNodeSelection = (nodeId: string) => {
    const mineModelFromRef = mineModelDataRef.current;
    const projectFromRef = projectRef.current;
    const apiRef = mineModelApiRef.current;

    if (!mineModelFromRef || !projectFromRef) {
      throw new Error('Issue with loaded mine data');
    }

    // find stope
    const isStope = mineModelFromRef.reticulationData.stopeLocations.find((sl) => sl.nodeId.toString() === nodeId.toString());
    let stopeData;
    const node = mineModelFromRef.reticulationData.nodes.find((sl) => sl.nodeId.toString() === nodeId.toString());

    const handleNavigation = () => {
      if (isStope) {
        stopeData = mineModelFromRef.reticulationData.stopes.find((sd) => sd.stopeId === isStope.stopeId);

        // navigate to stope
        handleStopeNavigation(isStope);
        handleStopeNavigation(isStope);
      } else if (node?.isPit || (node && node.chokeLength > 0)) {
        // if isPit or friction loop
        handleDefaultNavigation();
      } else {
        handleDefaultNavigation();
      }
    };

    // Add stope
    if (activeExclusiveActionRef.current === 'stope') {
      setNodeIdLoaded(nodeId);
      setActiveNodeId(nodeId);
      if (node?.isPit || (node && node.chokeLength > 0)) {
        handleNavigation();
      }
      return;
    }
    // remove stope
    if (isStope && activeExclusiveActionRef.current === 'remove-stope') {
      stopeData = mineModelFromRef.reticulationData.stopes.find((sd) => sd.stopeId === isStope.stopeId);

      deleteStope(
        projectFromRef.entityId,
        isStope.nodeId,
        isStope.stopeId,
        stopeData?.stopeName ?? '',
        apiRef?.deleteStope,
      );
      return;
    }
    // remove pit or friction loop

    if (activeExclusiveActionRef.current === 'remove-stope') {
      const nodeData = mineModelFromRef.reticulationData.nodes.find((sd) => sd.nodeId === nodeId);

      deleteStope(
        projectFromRef.entityId,
        nodeId,
        nodeData?.pit !== null || nodeData?.pit !== '' ? 'Pit' : 'Friction Loop',
        nodeData?.pit !== null || nodeData?.pit !== '' ? 'Pit' : 'Friction Loop',
        apiRef?.deleteStope,
      );
    }

    if (activeExclusiveActionRef.current === 'backfillPlant') {
      apiRef?.plotBackfillPlantColorKey(nodeId);
      setDefaultBackFillPlantNodeId(nodeId);
      return;
    }

    if (!activeExclusiveActionRef.current) {
      setNodeIdLoaded(nodeId);
    }
    handleNavigation();
  };

  const handleExternalStopeHover = (stopeLocation?: StopeLocationDto) => {
    mineModelApi?.hoverNode(stopeLocation?.nodeId);
  };

 const handleError = useCallback((ex: Error) => {
    // Clear any existing debounce timer
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }

    // Set a new debounce timer
    debounceTimer.current = setTimeout(() => {
      if (ex.message === 'No routes found') {
        enqueueSnackbar(`${ex.message}`, {
          variant: 'warning',
          title: 'Selected Backfill Plant',
        });
        handleDefaultNavigation();
      }
    });
  }, []);

  const utiliseCanvasElement = (
    canvasElement: HTMLCanvasElement,
    initialisedProject: ProjectDto,
    initialisedMineModel: MineModelDto,
    rheologyData: RheologyDataDto,
    customUnitFields: CustomUnitFields[],
  ) => {
    const api = initialiseCanvas(
      canvasElement,
      handleNodeSelection,
      handleRouteChange,
      menuState ? MAIN_MENU_OPEN_WIDTH : MAIN_MENU_CLOSED_WIDTH,
      SIDE_PANEL_OPEN_WIDTH,
      initialisedMineModel,
      initialisedProject.unitSystemPreference ?? UnitSystem.Metric,
      project?.fillType ?? FillType.Paste,
      rheologyData,
      project?.throughput ?? ThroughputControlType.DryTonnage,
      customUnitFields,
      defaultBackfillPlantNodeId?.toString() || '',
      handleError,
    );

    setMineModelApi(api);
  };

  const handleOnAddStopeForm = (stopeLocation: StopeLocationDto, stopeData: StopeDataDto, isPit: boolean, isFrictionLoop: boolean) => {
    mineModelApi?.addStope(stopeLocation, stopeData, isPit, isFrictionLoop);
    mineOverviewQuery.refetch();
    nodeNavigationRef.current = {
      stopeLocation,
      isPit,
      isFrictionLoop,
    };
  };

  React.useEffect(() => {
    const nodeNavigationResult = nodeNavigationRef.current;
    if (!nodeNavigationResult) return;
    const stopeLocation = nodeNavigationResult?.stopeLocation;
    const stopeExistsInMineModel = mineModel?.reticulationData.stopeLocations.some((x) => x.stopeId === stopeLocation?.stopeId);
    const stopeExistsInMineOverview = mineOverview?.some((x) => x.stopeIdentifier.stopeId === stopeLocation?.stopeId);

    if (nodeNavigationResult.isPit || nodeNavigationResult.isFrictionLoop) {
      nodeNavigationRef.current = null;
    }
    if (stopeExistsInMineModel && stopeExistsInMineOverview) {
      handleStopeNavigation(stopeLocation!);
      nodeNavigationRef.current = null;
    }

    mineModelApi?.hoverNode(stopeLocation?.nodeId);
    mineModelApi?.selectModelCanvas();
  }, [mineModel, mineOverview]);

  React.useEffect(() => {
    if (mineModel) {
      mineModelDataRef.current = mineModel;
    }
  }, [mineModel]);

  React.useEffect(() => {
    if (project) {
      projectRef.current = project;
    }
  }, [project]);

  React.useEffect(() => {
    if (initialRheologyDataset) {
      initialRheologyDatasetRef.current = initialRheologyDataset;
    }
  }, [initialRheologyDataset]);

  React.useEffect(() => {
    if (drawerOpen) {
      mineModelApi?.setDrawerWidth(SIDE_PANEL_OPEN_WIDTH);
    } else {
      mineModelApi?.setDrawerWidth(SIDE_PANEL_CLOSED_WIDTH);
    }
  }, [drawerOpen]);

  React.useEffect(() => {
    if (menuState) {
      mineModelApi?.setMainMenuWidth(MAIN_MENU_OPEN_WIDTH);
    } else {
      mineModelApi?.setMainMenuWidth(MAIN_MENU_CLOSED_WIDTH);
    }
  }, [menuState]);

  React.useEffect(() => {
    if (mineModelApi) {
      window.addEventListener('keydown', handleEscapeKey);
      exclusiveActionFunctionMap.current = {
        pan: mineModelApi.selectPan,
        rotate: mineModelApi.selectRotate,
        stope: mineModelApi.selectAddStope,
        'remove-stope': mineModelApi.selectAddStope,
        backfillPant: mineModelApi.selectBackfillPlant,
      };
    }

    return () => {
      window.removeEventListener('keydown', handleEscapeKey);
    };
  }, [mineModelApi]);

  // Load state
  if (queries.some((q) => q.isLoading) || initialRheologyDatasetIsLoading) {
    return (
      <Backdrop open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  // No data
  if (!mineModel || !mineOverview || !project || !initialRheologyDataset) {
    return (
      <AlertText severity="info">The admin has not uploaded any data for this project. For more information contact the administrator.</AlertText>
    );
  }

  // display model
  return (
    <ProjectProvider project={project}>
      <Switch>
        <Route exact path={path}>
          <ModelDrawer isOpen={drawerOpen} setIsOpen={setDrawerOpen}>
            {
              mineModelApi && (
                <StopesPanelPage
                  mineOverview={mineOverview}
                  mineModel={mineModel}
                  onStopeHover={handleExternalStopeHover}
                  onStopeSelect={handleStopeNavigation}
                  setSelectedRoute={mineModelApi?.setSelectedRouteFromSpoolIds}
                />
              )
            }
          </ModelDrawer>
        </Route>
        <Route exact path={`${path}/:stopeId/:tab?`}>
          { /* // TODO: add nested router instead of using params directly. */}
          <ModelDrawer backNavPath={url} backNavText="Stopes" isOpen={drawerOpen} setIsOpen={setDrawerOpen}>
            <StopePanelPage
              project={project}
              mineModel={mineModel}
              mineOverview={mineOverview}
              mineModelApi={mineModelApi}
              selectedRouteSpoolIds={selectedRouteSpoolIds}
              initialRheologyDataset={initialRheologyDataset}
              onStopeEdit={(nodeId: string) => setActiveNodeId(nodeId)}
              nodeId={nodeIdLoaded}
            />
          </ModelDrawer>
        </Route>
        <Route
          exact
          path="*"
          render={() => <Redirect to={url} />}
        />
      </Switch>
      <Canvas
        onLoaded={(canvasElement) => utiliseCanvasElement(canvasElement, project, mineModel, initialRheologyDataset, project?.customUnitFields)}
        className={clsx(classes.canvas, {
          [classes.panHover]: activeExclusiveAction === 'pan',
          [classes.rotate]: activeExclusiveAction === 'rotate',
        })}
      />
      <ModelDrawerTrigger handleClick={() => setDrawerOpen(true)} />
      <Box display="flex" flexDirection="column" alignItems="start">
        <ProjectAuthorise
          permission={Permission.ManageStopeData}
          render={(isAuthorised) => (
            <ToggleButtonGroup orientation="vertical" value={activeExclusiveAction} exclusive onChange={handleExclusiveActionChange}>
              <ToggleButton value="pan" aria-label="pan" onClick={mineModelApi?.selectPan}>
                <Tooltip title="Pan" placement="right">
                  <PanToolIcon />
                </Tooltip>
              </ToggleButton>
              <ToggleButton value="rotate" aria-label="rotate" onClick={mineModelApi?.selectRotate}>
                <Tooltip title="Rotate" placement="right">
                  <ThreeDRotationIcon />
                </Tooltip>
              </ToggleButton>
              <ToggleButton value="reset" aria-label="reset" onClick={mineModelApi?.selectReset}>
                <Tooltip title="Reset" placement="right">
                  <ReplayIcon />
                </Tooltip>
              </ToggleButton>
              {isAuthorised && (
                <ToggleButton value="stope" aria-label="stope" onClick={mineModelApi?.selectAddStope}>
                  <Tooltip title="Edit Node" placement="right">
                    <AdjustIcon />
                  </Tooltip>
                </ToggleButton>
              )}
              <ToggleButton value="backfillPlant" aria-label="backfillPlant" onClick={mineModelApi?.selectBackfillPlant}>
                <Tooltip title="Select Backfill Plant" placement="right">
                  <FormatColorFill />
                </Tooltip>
              </ToggleButton>
            </ToggleButtonGroup>
          )}
        />

        <Box my={1} />
        <ToggleButtonGroup orientation="vertical" value={activeVisualActions} onChange={handleVisualActionChange}>
          <ToggleButton value="pipes" aria-label="pipes" onClick={mineModelApi?.selectShowPipeTypes}>
            <Tooltip title="Toggle Pipe Types" placement="right">
              <LinearScaleIcon />
            </Tooltip>
          </ToggleButton>
          <ToggleButton value="focus" aria-label="focus" onClick={() => mineModelApi?.selectFocusRoute()} disabled={!stopeIsSelected}>
            <Tooltip title="Toggle Route Isolation" placement="right">
              <VisibilityIcon />
            </Tooltip>
          </ToggleButton>
        </ToggleButtonGroup>

        <Box my={1} />
        <ToggleButtonGroup orientation="vertical">
          <ToggleButton value="zoomIn" aria-label="zoom in" onClick={mineModelApi?.hglZoomIn}>
            <Tooltip title="HGL Zoom In" placement="right">
              <ZoomInIcon />
            </Tooltip>
          </ToggleButton>
          <ToggleButton value="zoomOut" aria-label="zoom out" onClick={mineModelApi?.hglZoomOut}>
            <Tooltip title="HGL Zoom Out" placement="right">
              <ZoomOutIcon />
            </Tooltip>
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>

      {
        activeNodeId && (
          <NodeForm
            project={project}
            nodeId={activeNodeId}
            mineModel={mineModel}
            onComplete={() => setActiveNodeId(undefined)}
            onAdd={(stopeLocation, stopeData, isPit, isFrictionLoop) => handleOnAddStopeForm(stopeLocation, stopeData, isPit, isFrictionLoop)}
            onUpdate={(stopeLocation, stopeData) => mineModelApi?.updateStope(stopeLocation, stopeData)}
          />
        )
      }
    </ProjectProvider>
  );
};

export default ProjectStopesPage;
