// @ts-nocheck

/**
* IMPORTANT: TS has been disabled due to issue with yup typescript support. As of writing we are on Version 0.32.9
* Best course of action will be upgrade in hope typing issue has been resolved.
*
* The issue is with the the array type .when clause e.g. `.when(['max', 'min'], (max, min, schema) =>` ts doesn't recognise the
* method signature but the logic runs successful and is accurate as per yup docs.
*
* When editing this file it is recommended to remove the ts-nocheck and then reinstate once changes have been made.
*/

import {
  BinderForm,
  FillType,
  MassConcentrationForm,
  MixerCoefficientsForm,
  PumpPressureForm,
  ThroughputControlType,
  TonnageForm,
  UcsCoefficientsForm,
  ViscosityCoefficientsForm,
  YieldStressCoefficientsForm
} from 'providers/api';
import {
  SchemaOf,
  array,
  number,
  object,
  string
} from 'yup';
import { isNumber } from 'utils';
import { ThroughputControlTypeLabel } from '../constant';

const YieldStressCoefficientsSchema: SchemaOf<YieldStressCoefficientsForm> = object().shape({
  coefficient1: number()
    .typeError('Yield stress constant A must be a valid number')
    .required('Yield Stress Constant A is required')
    .min(0, 'Yield Stress Constant A must be greater than or equal to 0'),
  coefficient2: number()
    .typeError('Yield stress power B must be a valid number')
    .required('Yield stress power B is required')
    .min(0, 'Yield stress power B must be greater than or equal to 0'),
});

const ViscosityCoefficientSchema: SchemaOf<ViscosityCoefficientsForm> = object().shape({
  coefficient1: number()
    .typeError('Plastic Viscosity Constant C must be a valid number')
    .required('Plastic Viscosity Constant C is required'),
  coefficient2: number()
    .typeError('Plastic Viscosity Power D must be a valid number')
    .required('Plastic Viscosity Power D is required')
    .when(
      'coefficient1', (coefficient1, schema) => (
        coefficient1 ? schema.max(coefficient1, 'Plastic Viscosity D must be less than Plastic Viscosity c') : schema
      ),
    ),
});

const UCSCoefficientsSchema: SchemaOf<UcsCoefficientsForm[]> = array().of(
  object().shape({
    targetDays: number()
      .typeError('Target days must be a valid number')
      .required('Target days is required')
      .positive('Target days must be greater than 0')
      .integer('Target days must be an integer'),
    coefficient1: number()
      .typeError('UCS coefficient 1 must be a valid number')
      .required('UCS coefficient 1 is required')
      .moreThan(0, 'UCS coefficient 1 should be greater than 0'),
    coefficient2: number()
      .typeError('UCS coefficient 2 must be a valid number')
      .required('UCS coefficient 2 is required')
      .lessThan(0, 'UCS coefficient 2 must be less than 0'),
    minUCS: number()
    .transform((originalValue) => (isNumber(originalValue) ? originalValue : null))
    .nullable()
    .notRequired(),
    maxUCS: number()
    .transform((originalValue) => (isNumber(originalValue) ? originalValue : null))
    .nullable()
    .notRequired()
    .when('minUCS', (min, schema) => (min ? schema.moreThan(min, 'Max UCS must be greater than Min UCS') : schema)),
  }),
);

const MixerCoefficientsSchema: SchemaOf<MixerCoefficientsForm> = object().shape({
  continuousMixerCoefficientC: number(),
  continuousMixerCoefficientD: number(),
  continuousMixerCoefficientM: number(),
  continuousMixerCoefficientN: number(),
  batchMixerCoefficientD: number(),
  batchMixerCoefficientM: number(),
  batchMixerCoefficientN: number(),
});

const PumpPressureSchema: SchemaOf<PumpPressureForm> = object().shape({
  max: number()
    .required('Maximum pump pressure is required')
    .min(0, 'Maximum pump pressure must be greater than or equal to 0')
    .lessThan(300, 'Maximum pump pressure must be less than 300'),
  default: number()
    .required('Default pump pressure is required')
    .min(0, 'Default pump pressure must be greater than or equal to 0')
    .when('max', (max, schema) => (max ? schema.max(max, 'Default pump pressure must be less than or equal to maximum pump pressure') : schema)),
});

const TonnageSchema: SchemaOf<TonnageForm> = (throughput: ThroughputControlType) => {
  const throughputLabel = ThroughputControlTypeLabel[throughput];

  return object().shape({
    min: number()
      .required(`${throughputLabel} min is required`)
      .min(0, `Minimum ${throughputLabel} must be greater than or equal to 0`)
      .max(999, `Minimum ${throughputLabel} must be less than 1000`)
      .integer(`Minimum ${throughputLabel} must be an integer`),

    max: number()
      .required(`${throughputLabel} max is required`)
      .min(0, `Maximum ${throughputLabel} must be greater than or equal to 0`)
      .max(1000, `Maximum ${throughputLabel} must be less than or equal to 1000`)
    .integer(`Maximum ${throughputLabel} must be an integer`)
      .when('min', (min, schema) => (min
        ? schema.moreThan(min, `Maximum ${throughputLabel} must be greater than minimum ${throughputLabel}`)
        : schema
      )),

    interval: number()
      .required(`${throughputLabel} interval is required`)
      .min(0, 'Interval must be greater than or equal to 0')
      .max(999, 'Interval must be less than 1000')
      .integer('Interval must be an integer')
      .when(['max', 'min'], (max, min, schema) => (max && min
        ? schema.lessThan(max - min, `${throughputLabel} interval should be less than (max - min)`)
        : schema
      )),

    default: number()
      .required(`${throughputLabel} default is required`)
      .min(0, `Default ${throughputLabel} must be greater than or equal to 0`)
      .max(1000, `Default ${throughputLabel} must be less than or equal to 1000`)
      .integer(`Default ${throughputLabel} must be an integer`)
      .when(['max', 'min'], (max, min, schema) => (max && min
        ? schema
            .min(min, `Default ${throughputLabel} must be greater than or equal to minimum ${throughputLabel}`)
            .max(max, `Default ${throughputLabel} must be less than or equal to maximum ${throughputLabel}`)
        : schema
      )),
  });
};

const BinderSchema: SchemaOf<BinderForm> = object().shape({
  min: number()
    .required('Binder min is required')
    .min(0, 'Minimum binder percentage must be greater than or equal to 0')
    .max(100, 'Minimum binder percentage must be less than or equal to 100'),
  max: number()
    .required('Binder max is required')
    .min(0, 'Maximum binder percentage must be greater than or equal to 0')
    .max(100, 'Maximum binder percentage must be less than or equal to 100')
    .when('min', (min, schema) => (min ? schema.moreThan(min, 'Maximum binder must be less than Minimum binder') : schema)),
  interval: number()
    .required('Binder interval is required')
    .min(0, 'Binder interval must be greater than or equal to 0')
    .max(100, 'Binder interval must be less than or equal to 100')
    .when(['max', 'min'], (max, min, schema) => (max && min
      ? schema.lessThan(max - min, 'Binder Interval should be less than (max - min)')
      : schema
    )),
});

const MassConcentrationSchema: SchemaOf<MassConcentrationForm> = object().shape({
  min: number()
    .required('Mass concentration min is required')
    .min(0, 'Minimum mass concentration must be greater than or equal to 0')
    .max(100, 'Minimum mass concentration must be less than 100'),
  max: number()
    .required('Mass concentration Max is required')
    .min(0, 'Maximum mass concentration must be greater than or equal to 0')
    .max(100, 'Maximum mass concentration must be less than or equal to 100')
    .when('min', (min, schema) => (min ? schema.moreThan(min, 'Maximum mass concentration must be greater than Minimum mass concentration') : schema)),
  interval: number()
    .required('Mass concentration interval is required')
    .min(0, 'Mass concentration interval must be greater than or equal to 0')
    .max(100, 'Mass concentration interval must be less than or equal to 100')
    .when(['max', 'min'], (max, min, schema) => (max && min
      ? schema.lessThan(max - min, 'Mass concentration interval should be less than (max - min)')
      : schema)),
});

const TailingsDrySolidsDensitySchema = number()
  .required('Tailings dry solids density is required')
  .moreThan(100, 'Tailings dry solids density must be greater than 1000')
  .lessThan(10_000, 'Tailings dry solids density must be less than 10,000');

const CarrierFluidDensitySchema = number()
  .required('Carrier fluid density is required')
  .moreThan(500, 'Carrier fluid density must be greater than 500')
  .lessThan(10_000, 'Carrier fluid density must be less than 10,000');

const BinderDensitySchema = number()
  .required('Binder density is required')
  .moreThan(1000, 'Binder density must be greater than 1000')
  .lessThan(10_000, 'Binder density must be less than 10,000');

const HeightOfCylinderSchema = number()
  .required('Slump cylinder height is required');

const FrictionLossFactorOfSafetySchema = number().nullable()
  .moreThan(0, 'Factor of Safety must be positive')
  .lessThan(10, 'Factor of Safety must be less than 10');

const generateRheologySchema = (fillType: FillType, throughput: ThroughputControlType) => object().shape({
  version: string().required('Version is required'),
  reference: string().required('Reference is required'),
  ucsCoefficients: fillType === FillType.Paste ? UCSCoefficientsSchema.required() : UCSCoefficientsSchema.optional(),
  mixerCoefficients: MixerCoefficientsSchema,
  pumpPressure: PumpPressureSchema,
  tailingsDrySolidsDensity: TailingsDrySolidsDensitySchema,
  carrierFluidDensity: CarrierFluidDensitySchema,
  binderDryParticleDensity: BinderDensitySchema,
  yieldStressCoefficients: YieldStressCoefficientsSchema,
  heightOfCylinder: HeightOfCylinderSchema,
  ...(fillType === FillType.Paste ? { binder: BinderSchema } : {}),
  tonnage: TonnageSchema(throughput),
  viscosityCoefficients: ViscosityCoefficientSchema,
  massConcentration: MassConcentrationSchema,
  frictionLossFactorOfSafety: FrictionLossFactorOfSafetySchema,
}).defined();

export default generateRheologySchema;
