import PCNode from '../DiagraExtended/PCNode';
import * as constants from './DConstants';
import Pipe from './Pipe';
import Point from './Point';
import { SpoolJSON } from './types';

const radiansToDegrees = (radians: number) => {
  const pi = Math.PI;
  return radians * (180 / pi);
};

// Spools and Points are drawn separately
// Spools therefore have points for interaction
// rather than just vectors for drawing.
export default class Spool {
  spoolId: string;

  pointAId: string;

  pointBId: string;

  pipeId: string;

  loopOrChoke: number;

  hitBool: boolean;

  selected: boolean;

  activeBool: boolean;

  lineWidth: number;

  hitColour: string;

  selectedColour: string;

  activeColour: string;

  colour: string | CanvasGradient;

  hitWidth: number;

  selectedWidth: number;

  activeWidth: number;

  pointA?: PCNode;

  pointB?: PCNode;

  pipe?: Pipe;

  pipeColour: string;

  boreHoleAngle?: number;

  constructor(
    data: SpoolJSON,
    points?: Record<string, PCNode>,
    pipes?: Record<string, Pipe>,
  ) {
    const pointA = points && Object.values(points).find((o) => data.pointAId === o.pointId);
    const pointB = points && Object.values(points).find((o) => data.pointBId === o.pointId);

    this.spoolId = data.spoolId;
    this.pointAId = data.pointAId;
    this.pointBId = data.pointBId;
    this.pipeId = data.pipeId || '1';
    this.loopOrChoke = data.loopOrChoke || 0;
    this.pointA = pointA;
    this.pointB = pointB;
    if (pipes) this.pipe = Object.values(pipes).find((o) => this.pipeId === o.pipeId);
    this.hitBool = false;
    this.selected = false;
    this.activeBool = false;
    this.hitColour = constants.DEFAULT_HIT_COLOUR;
    this.selectedColour = constants.DEFAULT_SELECTED_COLOUR;
    this.activeColour = constants.DEFAULT_ACTIVE_COLOUR;
    this.colour = constants.DEFAULT_SPOOL_COLOUR;
    this.lineWidth = constants.DEFAULT_SPOOL_WIDTH;
    this.hitWidth = constants.SPOOL_HIT_WIDTH;
    this.selectedWidth = constants.SPOOL_SELECTED_WIDTH;
    this.activeWidth = constants.SPOOL_ACTIVE_WIDTH;
    this.pipeColour = constants.DEFAULT_SPOOL_COLOUR;

    // INFO: Friction loop is not included here. Not important for angle calcs
    // bore hold angle > 60 = bore hole
    // could go up borehole so use absolute
    if (pointA && pointB) {
      const verticalDistance = pointB.coords.z - pointA.coords.z;
      const horizontalDistance = Math.sqrt(((pointB.coords.x - pointA.coords.x) ** 2) + ((pointB.coords.y - pointA.coords.y) ** 2));
      this.boreHoleAngle = Math.abs(radiansToDegrees(Math.atan(verticalDistance / horizontalDistance)));
    }
  }

  pointIn(iD: string) {
    return (this.pointAId === iD || this.pointBId === iD);
  }

  returnOppositePointId(point: string | number | Point): string | number | undefined {
    if (typeof point === 'string' || typeof point === 'number') {
      if (point === this.pointAId) return this.pointBId;
      if (point === this.pointBId) return this.pointAId;
    } else {
      if (point.pointId === this.pointAId) return this.pointBId;
      if (point.pointId === this.pointBId) return this.pointAId;
    }
    return undefined; // TODO: could improve this
  }

  returnOppositePoint(point: string | Point | undefined): Point | undefined {
    if (typeof this.pointA !== 'undefined' || typeof this.pointB !== 'undefined') {
      if (typeof point === 'string') {
        if (point === this.pointAId) return this.pointB;
        if (point === this.pointBId) return this.pointA;
      } else if (typeof point !== 'undefined') {
        if (point.pointId === this.pointAId) return this.pointB;
        if (point.pointId === this.pointBId) return this.pointA;
      }
    } else {
      console.log('error: Point objects not defined on stope', this);
    }
    return undefined;
  }

  length(): number {
    if (this.pointA && this.pointB) {
      let len = 0;
      len += this.loopOrChoke || 0;
      len += this.pointA.coords.length3dTo(this.pointB.coords);
      return len;
    }
    return 0;
  }

  deltaZ(): number { // positive for A < B
    if (this.pointA && this.pointB) {
      return this.pointA.coords.z - this.pointB.coords.z;
    }
    return 0;
  }

  declineAngle(): number { // radians between -pi/2 and p/2 where negative for declination
    const len = this.length();
    const dz = this.deltaZ();
    return Math.asin(dz / len);
  }

  // deltaP(recipe: PasteRecipe, rheology: PasteRheology) { // recipe has frictions for each pipe type, lookup PIPE_ID as key
  //   const length = this.length();
  //   const friction = recipe.friction(rheology, this.pipe);
  //   // const deltaKPa = length * friction[0];
  //   // const deltaMPerM = length * friction[1];
  //   const deltaKPa = length * friction[0] - recipe.kPaToM(this.deltaZ());
  //   const deltaMPerM = length * friction[1] - this.deltaZ();
  //   return [deltaKPa, deltaMPerM];
  // };
  render(ctx: any) {
    if (this.pointA && this.pointB) {
      if (this.hitBool) this.draw(ctx, this.hitColour, this.hitWidth);
      else if (this.selected) {
        this.draw(ctx, this.selectedColour, this.selectedWidth);
      } else if (this.activeBool) {
        this.draw(ctx, this.activeColour, this.activeWidth);
      } else {
        this.draw(ctx, this.colour, this.lineWidth);
      }
    }
  }

  draw(ctx: any, colour: string | CanvasGradient, lineWidth: number) {
    ctx.strokeStyle = colour;
    ctx.lineWidth = lineWidth;
    ctx.beginPath();
    ctx.moveTo(this.pointA!.screenVector.x, this.pointA!.screenVector.y);
    ctx.lineTo(this.pointB!.screenVector.x, this.pointB!.screenVector.y);
    ctx.stroke();
  }

  generateJSON(): SpoolJSON {
    return {
      spoolId: this.spoolId,
      pointAId: this.pointAId,
      pointBId: this.pointBId,
      pipeId: this.pipeId,
      loopOrChoke: this.loopOrChoke,
    };
  }
}
