import { Instant } from '@js-joda/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { FormSpacing, FormikActions } from 'components/Form';
import ProgressButton from 'components/ProgressButton';
import useForm from 'hooks/useForm';
import uuid from 'modules/project/ProjectAreaPage/ProjectStopesPage/canvas/Diagra/uuid';
import {
  BinderDto,
  BinderForm,
  FillType,
  MassConcentrationDto,
  MassConcentrationForm,
  ProjectDto,
  PumpPressureDto,
  PumpPressureForm,
  RheologyDataDto,
  RheologyMutation,
  RheologyDataForm as ServerRheologyDataForm,
  TonnageDto,
  TonnageForm,
  UcsCoefficientsDto,
  UcsCoefficientsForm,
  UnitSystem,
  ViscosityCoefficientsDto,
  ViscosityCoefficientsForm
} from 'providers/api';
import { UseMutationResult } from 'react-query';
import { Weaken } from 'utils';
import InputGrid from './InputGrid/InputGrid';
import { InputRecord, InputType } from './InputGrid/InputGridTypes';
import generateRheologySchema from './RheologyDataFormSchema';
import { ThroughputControlTypeImperialAbbrev, ThroughputControlTypeMetricAbbrev, ThroughputControlTypeLabel } from '../constant';

const percentageToDecimal = (percent: number) => percent / 100;
const decimalToPercentage = (decimal: number) => decimal * 100;

const UCSheaders = ['Days', 'UCS Coefficient 1', 'UCS Coefficient 2', 'Min UCS', 'Max UCS'];
interface RheologyDataFormProps {
  project: ProjectDto;
  rheologyData?: RheologyDataDto;
  mutation: UseMutationResult<string | void, unknown, RheologyMutation>;
}

const ucsCoefficientsDictionaryToFormArray = (data: { [key: string]: UcsCoefficientsDto }): UcsCoefficientsForm[] => Object.entries(data)
  .map(([key, value]) => ({
    targetDays: parseInt(key, 10),
    coefficient1: value.coefficient1,
    coefficient2: value.coefficient2,
    minUCS: value.minUCS,
    maxUCS: value.maxUCS,
  }));

const pumpPressureToForm = (data: PumpPressureDto): PumpPressureForm => ({
  max: data.max,
  default: data.default,
});

const tonnageToForm = (data: TonnageDto): TonnageForm => ({
  min: data.min,
  max: data.max,
  interval: data.interval,
  default: data.default,
});

const viscosityToForm = (data: ViscosityCoefficientsDto): ViscosityCoefficientsForm => ({
  coefficient1: data.coefficient1,
  coefficient2: data.coefficient2,
});

const massConcentrationToUIForm = (massConcentration: MassConcentrationDto): MassConcentrationForm => ({
  min: decimalToPercentage(massConcentration.min),
  max: decimalToPercentage(massConcentration.max),
  interval: decimalToPercentage(massConcentration.interval),
});

const massConcentrationToSubmitForm = (massConcentration: MassConcentrationForm): MassConcentrationForm => ({
  min: percentageToDecimal(massConcentration.min),
  max: percentageToDecimal(massConcentration.max),
  interval: percentageToDecimal(massConcentration.interval),
});

const binderToUIForm = (binder: BinderDto): BinderForm => ({
  min: decimalToPercentage(binder.min),
  max: decimalToPercentage(binder.max),
  interval: decimalToPercentage(binder.interval),
});

const binderToSubmitForm = (binder: BinderForm): BinderForm => ({
  min: percentageToDecimal(binder.min),
  max: percentageToDecimal(binder.max),
  interval: percentageToDecimal(binder.interval),
});

const getInitialBinder = (fillType: FillType, currentBinderValue?: BinderDto): undefined | BinderForm => (fillType === FillType.Paste && currentBinderValue
  ? binderToUIForm(currentBinderValue)
  : undefined
);

const instantToDate = (instant: Instant) => new Date(instant.toEpochMilli());

const RheologyDataForm = ({ project, rheologyData, mutation }: RheologyDataFormProps) => {
  const isHydraulicFillType = FillType.Hydraulic === project.fillType;

  const formSchema = generateRheologySchema(project.fillType, project.throughput);

  const { formik, helpers } = useForm<Weaken<ServerRheologyDataForm>>(
    {
      mutation,
      formikConfig: {
        initialValues: {
          version: rheologyData?.version,
          reference: rheologyData?.reference,
          ucsCoefficients: rheologyData ? ucsCoefficientsDictionaryToFormArray(rheologyData.ucsCoefficients) : undefined,
          mixerCoefficients: rheologyData?.mixerCoefficients ?? {},
          pumpPressure: rheologyData ? pumpPressureToForm(rheologyData.pumpPressure) : undefined,
          tailingsDrySolidsDensity: rheologyData?.tailingsDrySolidsDensity,
          carrierFluidDensity: rheologyData?.carrierFluidDensity,
          binderDryParticleDensity: rheologyData?.binderDryParticleDensity,
          yieldStressCoefficients: rheologyData?.yieldStressCoefficients,
          heightOfCylinder: rheologyData?.heightOfCylinder,
          binder: getInitialBinder(project.fillType, rheologyData?.binder),
          tonnage: rheologyData ? tonnageToForm(rheologyData.tonnage) : undefined,
          viscosityCoefficients: rheologyData ? viscosityToForm(rheologyData.viscosityCoefficients) : undefined,
          massConcentration: rheologyData ? massConcentrationToUIForm(rheologyData.massConcentration) : undefined,
          frictionLossFactorOfSafety: rheologyData?.frictionLossFactorOfSafety || 1,
          generatedUtc: rheologyData ? instantToDate(rheologyData.generated) : new Date(),
        },
        onSubmit: (form, { setSubmitting }) => {
          // Hydraulic pours do not require binder values
          const newBinder = project.fillType === FillType.Hydraulic ? { min: 0, max: 0, interval: 0 } : form.binder;

          const processedForm = {
            ...form,
            binder: newBinder ? binderToSubmitForm(newBinder) : undefined,
            massConcentration: form.massConcentration ? massConcentrationToSubmitForm(form.massConcentration) : undefined,
            ucsCoefficients: form.ucsCoefficients || [],
          };

          setSubmitting(true);
          mutation.mutate(
            {
              projectId: project.entityId,
              projectName: project.name,
              data: processedForm as ServerRheologyDataForm,
              rheologyDatasetId: rheologyData?.rheologyDataSetId,
            },
            {
              onSettled: () => {
                setSubmitting(false);
              },
            },
          );
        },
        validationSchema: formSchema,
      },
    },
  );

  const {
    values,
    errors,
    isValid,
    isSubmitting,
    dirty,
    handleBlur,
    handleSubmit,
    setFieldValue,
    handleChange,
  } = formik;

  const inputType: InputType[] = [
    {
      name: 'days',
      label: 'days',
      type: 'number',
      value: '',
      size: 3,
    },
    {
      name: 'Co 1',
      label: 'UCS Coefficient 1',
      type: 'number',
      value: '',
      size: 3,
    },
    {
      name: 'Co 2',
      label: 'UCS Coefficient 2',
      type: 'number',
      value: '',
      size: 3,
    },
    {
      name: 'Min UCS',
      label: 'Min UCS',
      type: 'number',
      value: '',
      size: 3,
    },
    {
      name: 'Max UCS',
      label: 'Max UCS',
      type: 'number',
      value: '',
      size: 3,
    },
  ];

  const handleUpdateRow = (newValues: InputRecord[]) => {
    const newUCSValue: UcsCoefficientsForm[] = newValues.reduce(
      (accumulator: UcsCoefficientsForm[], current: InputRecord) => ([
        ...accumulator,
        {
          targetDays: parseInt(current.inputs[0].value, 10),
          coefficient1: parseFloat(current.inputs[1].value),
          coefficient2: parseFloat(current.inputs[2].value),
          minUCS: parseFloat(current.inputs[3].value),
          maxUCS: parseFloat(current.inputs[4].value),
        } as UcsCoefficientsForm,
      ]),
      [],
    );
    setFieldValue('ucsCoefficients', newUCSValue, true);
  };

  const ucsCoefficientFormToInputRecord = (data: UcsCoefficientsForm[]): InputRecord[] => data.map((ucsForm) => ({
    uniqueKey: uuid(),
    inputs: [
      { ...inputType[0], value: `${ucsForm.targetDays}` },
      { ...inputType[1], value: `${ucsForm.coefficient1}` },
      { ...inputType[2], value: `${ucsForm.coefficient2}` },
      { ...inputType[3], value: `${ucsForm.minUCS ?? ''}` },
      { ...inputType[4], value: `${ucsForm.maxUCS ?? ''}` },
    ],
  }));

  const initialUcsCoefficientsInputList = values.ucsCoefficients ? ucsCoefficientFormToInputRecord(values.ucsCoefficients) : [];
  const tonnageLabel = ThroughputControlTypeLabel[project.throughput];
  const tonnageAbbrevSuffix = project.unitSystemPreference === UnitSystem.Imperial
  ? ThroughputControlTypeImperialAbbrev[project.throughput]
  : ThroughputControlTypeMetricAbbrev[project.throughput];

  const submitButton = [
    'submit',
    (
      <ProgressButton
        variant="contained"
        color="primary"
        type="submit"
        disabled={!dirty || !isValid}
        loading={isSubmitting}
      >
        Submit
      </ProgressButton>
    ),
  ];

  return (
    <form onSubmit={handleSubmit}>
      {/* Project Details */}
      <Typography variant="subtitle1">Rheology Details</Typography>
      <Box component={Paper} p={2} mb={2}>
        <FormSpacing>
          <TextField
            required
            autoFocus
            fullWidth
            id="version"
            name="version"
            label="Rheology Version"
            value={values.version}
            onChange={handleChange}
            onBlur={handleBlur}
            error={helpers.hasError('version')}
            helperText={helpers.getErrorHelpText('version')}
            autoComplete="off"
          />
          <TextField
            required
            fullWidth
            id="reference"
            name="reference"
            label="Reference"
            value={values.reference}
            onChange={handleChange}
            onBlur={handleBlur}
            error={helpers.hasError('reference')}
            helperText={helpers.getErrorHelpText('reference')}
            autoComplete="off"
          />
        </FormSpacing>
      </Box>

      {/* Constant Details */}
      <Box component={Paper} p={2} mb={2}>
        <FormSpacing>
          <TextField
            required
            fullWidth
            id="yieldStressA"
            name="yieldStressCoefficients.coefficient1"
            label="Yield Stress Constant A"
            value={values.yieldStressCoefficients?.coefficient1}
            onChange={handleChange}
            onBlur={handleBlur}
            error={helpers.hasError('yieldStressCoefficients.coefficient1')}
            helperText={helpers.getErrorHelpText('yieldStressCoefficients.coefficient1')}
            autoComplete="off"
          />
          <TextField
            required
            fullWidth
            id="yieldStressPowerB"
            name="yieldStressCoefficients.coefficient2"
            label="Yield Stress Power B"
            value={values.yieldStressCoefficients?.coefficient2}
            onChange={handleChange}
            onBlur={handleBlur}
            error={helpers.hasError('yieldStressCoefficients.coefficient2')}
            helperText={helpers.getErrorHelpText('yieldStressCoefficients.coefficient2')}
            autoComplete="off"
          />
          <TextField
            required
            fullWidth
            id="viscosityConstantC"
            name="viscosityCoefficients.coefficient1"
            label="Plastic Viscosity Constant C"
            value={values.viscosityCoefficients?.coefficient1}
            onChange={handleChange}
            onBlur={handleBlur}
            error={helpers.hasError('viscosityCoefficients.coefficient1')}
            helperText={helpers.getErrorHelpText('viscosityCoefficients.coefficient1')}
            autoComplete="off"
          />
          <TextField
            required
            fullWidth
            id="viscosityPowerD"
            name="viscosityCoefficients.coefficient2"
            label="Plastic Viscosity Power D"
            value={values.viscosityCoefficients?.coefficient2}
            onChange={handleChange}
            onBlur={handleBlur}
            error={helpers.hasError('viscosityCoefficients.coefficient2')}
            helperText={helpers.getErrorHelpText('viscosityCoefficients.coefficient2')}
            autoComplete="off"
          />
          <TextField
            required
            fullWidth
            id="solidsDensity"
            name="tailingsDrySolidsDensity"
            label="Solids Density (kg/m3)"
            value={values.tailingsDrySolidsDensity}
            onChange={handleChange}
            onBlur={handleBlur}
            error={helpers.hasError('tailingsDrySolidsDensity')}
            helperText={helpers.getErrorHelpText('tailingsDrySolidsDensity')}
            autoComplete="off"
          />
        </FormSpacing>
      </Box>

      <Box component={Paper} p={2} mb={2}>
        <FormSpacing>
          <TextField
            fullWidth
            id="frictionLossFactorOfSafety"
            name="frictionLossFactorOfSafety"
            label="Friction Loss Factor of Safety (FOS)"
            value={values.frictionLossFactorOfSafety}
            onChange={handleChange}
            onBlur={handleBlur}
            error={helpers.hasError('frictionLossFactorOfSafety')}
            helperText={helpers.getErrorHelpText('factor Of Safety')}
            autoComplete="off"
          />
        </FormSpacing>
      </Box>

      <Box component={Paper} p={2} mb={2}>
        <FormSpacing>
          <TextField
            required
            fullWidth
            id="maxPumpPressure"
            name="pumpPressure.max"
            label="Maximum Pump Pressure (bar)"
            value={values.pumpPressure?.max}
            onChange={handleChange}
            onBlur={handleBlur}
            error={helpers.hasError('pumpPressure.max')}
            helperText={helpers.getErrorHelpText('pumpPressure.max')}
            autoComplete="off"
          />
          <TextField
            required
            fullWidth
            id="defaultPumpPressure"
            name="pumpPressure.default"
            label="Default Pump Pressure (bar)"
            value={values.pumpPressure?.default}
            onChange={handleChange}
            onBlur={handleBlur}
            error={helpers.hasError('pumpPressure.default')}
            helperText={helpers.getErrorHelpText('pumpPressure.default')}
            autoComplete="off"
          />
        </FormSpacing>
      </Box>

      <Box component={Paper} p={2} mb={2}>
        <FormSpacing>
          <TextField
            required
            fullWidth
            id="carrierFluidDensity"
            name="carrierFluidDensity"
            label="Carrier Fluid Density (kg/m3)"
            value={values.carrierFluidDensity}
            onChange={handleChange}
            onBlur={handleBlur}
            error={helpers.hasError('carrierFluidDensity')}
            helperText={helpers.getErrorHelpText('carrierFluidDensity')}
            autoComplete="off"
          />
          <TextField
            required
            fullWidth
            id="binderDensity"
            name="binderDryParticleDensity"
            label="Binder Density (kg/m3)"
            value={values.binderDryParticleDensity}
            onChange={handleChange}
            onBlur={handleBlur}
            error={helpers.hasError('binderDryParticleDensity')}
            helperText={helpers.getErrorHelpText('binderDryParticleDensity')}
            autoComplete="off"
          />
        </FormSpacing>
      </Box>

      <Box component={Paper} p={2} mb={2}>
        <FormSpacing>
          <TextField
            required
            fullWidth
            id="slumpCylinderHeight"
            name="heightOfCylinder"
            label="Slump Cylinder Height (m)"
            value={values.heightOfCylinder}
            onChange={handleChange}
            onBlur={handleBlur}
            error={helpers.hasError('heightOfCylinder')}
            helperText={helpers.getErrorHelpText('heightOfCylinder')}
            autoComplete="off"
          />
        </FormSpacing>
      </Box>

      {/* Tonnage */}
      <Typography variant="subtitle1">
        {`${tonnageLabel || ''} Details`}
      </Typography>
      <Box component={Paper} p={2} mb={2}>
        <FormSpacing>
          <TextField
            required
            fullWidth
            id="tonnageMin"
            name="tonnage.min"
            label={`${tonnageLabel} Min (${tonnageAbbrevSuffix})`}
            value={values.tonnage?.min}
            onChange={handleChange}
            onBlur={handleBlur}
            error={helpers.hasError('tonnage.min')}
            helperText={helpers.getErrorHelpText('tonnage.min')}
            autoComplete="off"
          />
          <TextField
            required
            fullWidth
            id="tonnageMax"
            name="tonnage.max"
            label={`${tonnageLabel} Max (${tonnageAbbrevSuffix})`}
            value={values.tonnage?.max}
            onChange={handleChange}
            onBlur={handleBlur}
            error={helpers.hasError('tonnage.max')}
            helperText={helpers.getErrorHelpText('tonnage.max')}
            autoComplete="off"
          />
          <TextField
            required
            fullWidth
            id="tonnageInterval"
            name="tonnage.interval"
            label={`${tonnageLabel} Interval ${tonnageAbbrevSuffix}`}
            value={values.tonnage?.interval}
            onChange={handleChange}
            onBlur={handleBlur}
            error={helpers.hasError('tonnage.interval')}
            helperText={helpers.getErrorHelpText('tonnage.interval')}
            autoComplete="off"
          />
          <TextField
            required
            fullWidth
            id="tonnageDefault"
            name="tonnage.default"
            label={`${tonnageLabel} Default (${tonnageAbbrevSuffix})`}
            value={values.tonnage?.default}
            onChange={handleChange}
            onBlur={handleBlur}
            error={helpers.hasError('tonnage.default')}
            helperText={helpers.getErrorHelpText('tonnage.default')}
            autoComplete="off"
          />
        </FormSpacing>
      </Box>

      {/* Mass */}
      <Typography variant="subtitle1">Mass Concentration Details</Typography>
      <Box component={Paper} p={2} mb={2}>
        <FormSpacing>
          <TextField
            required
            fullWidth
            id="massConcMin"
            name="massConcentration.min"
            label="Mass Conc Min (%m)"
            value={values.massConcentration?.min}
            onChange={handleChange}
            onBlur={handleBlur}
            error={helpers.hasError('massConcentration.min')}
            helperText={helpers.getErrorHelpText('massConcentration.min')}
            autoComplete="off"
          />
          <TextField
            required
            fullWidth
            id="massConcMax"
            name="massConcentration.max"
            label="Mass Conc Max (%m)"
            value={values.massConcentration?.max}
            onChange={handleChange}
            onBlur={handleBlur}
            error={helpers.hasError('massConcentration.max')}
            helperText={helpers.getErrorHelpText('massConcentration.max')}
            autoComplete="off"
          />
          <TextField
            required
            fullWidth
            id="massConcInterval"
            name="massConcentration.interval"
            label="Mass Conc Interval (%)"
            value={values.massConcentration?.interval}
            onChange={handleChange}
            onBlur={handleBlur}
            error={helpers.hasError('massConcentration.interval')}
            helperText={helpers.getErrorHelpText('massConcentration.interval')}
            autoComplete="off"
          />
        </FormSpacing>
      </Box>

      {/* UCS Coefficients */}
      {
        !isHydraulicFillType
        && (
        <>
          <Typography variant="subtitle1">UCS Coefficients:</Typography>
          <Box component={Paper} p={2} mb={2}>

            <Box display="flex" justifyContent="space-between" marginBottom={2}>
              <Box>
                <FormSpacing>
                  <Grid>
                    <InputGrid
                      inputList={initialUcsCoefficientsInputList}
                      inputType={inputType}
                      headers={UCSheaders}
                      onChange={handleUpdateRow}
                    />
                    {errors.ucsCoefficients && Array.isArray(errors.ucsCoefficients) && (
                    <div>
                      {errors.ucsCoefficients.map((rowErrors, rowIndex) => {
                      const rowIdentifier = rowErrors?.uniqueId || rowIndex;
                      return (
                        <div key={`error-row-${rowIdentifier}`}>
                          {rowErrors && Object.keys(rowErrors).map((errorKey) => (
                            <Typography key={`error-${rowIdentifier}-${errorKey}`} variant="body2" color="error">
                              {`Row ${rowIndex + 1}: ${rowErrors[errorKey]}`}
                            </Typography>
                          ))}
                        </div>
                      );
                    })}
                    </div>
                )}
                  </Grid>
                </FormSpacing>
              </Box>
            </Box>
          </Box>
        </>
)
      }

      {/* Spacer */}
      <Grid item xs={4}>
        <Box mt={2} />
      </Grid>

      {/* Binder */}
      {
        !isHydraulicFillType
        && (
        <>
          <Typography variant="subtitle1">Binder Details</Typography>
          <Box component={Paper} p={2} mb={2}>
            <FormSpacing>
              <TextField
                required={!isHydraulicFillType}
                fullWidth
                id="cementMin"
                name="binder.min"
                label="Binder Min (%)"
                value={values.binder?.min}
                onChange={handleChange}
                onBlur={handleBlur}
                error={helpers.hasError('binder.min')}
                helperText={helpers.getErrorHelpText('binder.min')}
                autoComplete="off"
              />
              <TextField
                required={!isHydraulicFillType}
                fullWidth
                id="cementMax"
                name="binder.max"
                label="Binder Max (%)"
                value={values.binder?.max}
                onChange={handleChange}
                onBlur={handleBlur}
                error={helpers.hasError('binder.max')}
                helperText={helpers.getErrorHelpText('binder.max')}
                autoComplete="off"
              />
              <TextField
                required={!isHydraulicFillType}
                fullWidth
                id="cementInterval"
                name="binder.interval"
                label="Binder Interval (%)"
                value={values.binder?.interval}
                onChange={handleChange}
                onBlur={handleBlur}
                error={helpers.hasError('binder.interval')}
                helperText={helpers.getErrorHelpText('binder.interval')}
                autoComplete="off"
              />
            </FormSpacing>
          </Box>
        </>
      )
}

      {/* Mixer Coefficiency */}
      <Typography variant="subtitle1">Mixer Coefficiency</Typography>
      <Box component={Paper} p={2} mb={2}>
        <FormSpacing>
          <TextField
            fullWidth
            id="continuousMixerCoefficientC"
            name="mixerCoefficients.continuousMixerCoefficientC"
            label="Continuous Mixer Coefficient C"
            value={values.mixerCoefficients?.continuousMixerCoefficientC}
            onChange={handleChange}
            onBlur={handleBlur}
            error={helpers.hasError('mixerCoefficients.continuousMixerCoefficientC')}
            helperText={helpers.getErrorHelpText('mixerCoefficients.continuousMixerCoefficientC')}
            autoComplete="off"
          />
          <TextField
            fullWidth
            id="continuousMixerCoefficientD"
            name="mixerCoefficients.continuousMixerCoefficientD"
            label="Continuous Mixer Coefficient D"
            value={values.mixerCoefficients?.continuousMixerCoefficientD}
            onChange={handleChange}
            onBlur={handleBlur}
            error={helpers.hasError('mixerCoefficients.continuousMixerCoefficientD')}
            helperText={helpers.getErrorHelpText('mixerCoefficients.continuousMixerCoefficientD')}
            autoComplete="off"
          />
          <TextField
            fullWidth
            id="continuousMixerCoefficientM"
            name="mixerCoefficients.continuousMixerCoefficientM"
            label="Continuous Mixer Coefficient M"
            value={values.mixerCoefficients?.continuousMixerCoefficientM}
            onChange={handleChange}
            onBlur={handleBlur}
            error={helpers.hasError('mixerCoefficients.continuousMixerCoefficientM')}
            helperText={helpers.getErrorHelpText('mixerCoefficients.continuousMixerCoefficientM')}
            autoComplete="off"
          />
          <TextField
            fullWidth
            id="continuousMixerCoefficientN"
            name="mixerCoefficients.continuousMixerCoefficientN"
            label="Continuous Mixer Coefficient N"
            value={values.mixerCoefficients?.continuousMixerCoefficientN}
            onChange={handleChange}
            onBlur={handleBlur}
            error={helpers.hasError('mixerCoefficients.continuousMixerCoefficientN')}
            helperText={helpers.getErrorHelpText('mixerCoefficients.continuousMixerCoefficientN')}
            autoComplete="off"
          />
          <TextField
            fullWidth
            id="batchMixerCoefficientD"
            name="mixerCoefficients.batchMixerCoefficientD"
            label="Batch Mixer Coefficient D"
            value={values.mixerCoefficients?.batchMixerCoefficientD}
            onChange={handleChange}
            onBlur={handleBlur}
            error={helpers.hasError('mixerCoefficients.batchMixerCoefficientD')}
            helperText={helpers.getErrorHelpText('mixerCoefficients.batchMixerCoefficientD')}
            autoComplete="off"
          />
          <TextField
            fullWidth
            id="batchMixerCoefficientM"
            name="mixerCoefficients.batchMixerCoefficientM"
            label="Batch Mixer Coefficient M"
            value={values.mixerCoefficients?.batchMixerCoefficientM}
            onChange={handleChange}
            onBlur={handleBlur}
            error={helpers.hasError('mixerCoefficients.batchMixerCoefficientM')}
            helperText={helpers.getErrorHelpText('mixerCoefficients.batchMixerCoefficientM')}
            autoComplete="off"
          />
          <TextField
            fullWidth
            id="batchMixerCoefficientN"
            name="mixerCoefficients.batchMixerCoefficientN"
            label="Batch Mixer Coefficient N"
            value={values.mixerCoefficients?.batchMixerCoefficientN}
            onChange={handleChange}
            onBlur={handleBlur}
            error={helpers.hasError('mixerCoefficients.batchMixerCoefficientN')}
            helperText={helpers.getErrorHelpText('mixerCoefficients.batchMixerCoefficientN')}
            autoComplete="off"
          />
        </FormSpacing>
      </Box>

      <FormikActions
        formik={formik}
        mutation={mutation}
        submitText="Submit"
        right={
          [
            'reset',
            submitButton as [string, JSX.Element],
          ]
        }
      />
    </form>
  );
};

export default RheologyDataForm;
