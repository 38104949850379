import PCNode from '../DiagraExtended/PCNode';
import PCSpool from '../DiagraExtended/PCSpool';
import * as GConstants from '../GConstants';
import GuiState from './GUI/GuiState';
import ModelState from './ModelState';
import { plotHGLTelemetry } from './Telemetry/plotTelemetry';
import plotColorKey from './colorKey/colorKey';

function plotBackground(ctx: CanvasRenderingContext2D, width: number, height: number) {
  const context = ctx;
  const cx = width * 0.3;
  const cy = height * 0.6;
  const grad = context.createRadialGradient(cx, cy, 0, cx, cy, Math.sqrt(cx * cx + cy * cy));
  grad.addColorStop(0, 'rgba(0, 0, 0, 0.7)');
  grad.addColorStop(1, 'rgba(18, 18, 18, 0)');
  context.fillStyle = GConstants.BACKGROUND_COLOR;
  context.fillRect(0, 0, width, height); // draw green side
  context.fillStyle = grad;
  context.fillRect(0, 0, width, height); // draw gradient
}

function plotOriginArrow(context: CanvasRenderingContext2D, originNodes: PCNode[] | undefined, activeOriginNode: PCNode | undefined) {
  if (originNodes) {
    originNodes.forEach((originNode) => {
      const o = originNode;
      context.fillStyle = o.pointId === activeOriginNode?.pointId ? GConstants.DARK_RED : GConstants.LIGHT_GRAY;
      context.fillRect(o.screenVector.x - 6, o.screenVector.y - 35, 11, 20);
      context.beginPath();
      context.moveTo(o.screenVector.x - 11, o.screenVector.y - 16);
      context.lineTo(o.screenVector.x + 10, o.screenVector.y - 16);
      context.lineTo(o.screenVector.x, o.screenVector.y);
      context.lineTo(o.screenVector.x - 11, o.screenVector.y - 16);
      context.closePath();
      context.fill();
    });
  }
}

function renderSpools(context: CanvasRenderingContext2D, spools: PCSpool[], showPipeColour : boolean) {
  const activeSpools = spools.filter((spool) => spool.activeBool);
  const selectedSpools = spools.filter((spool) => spool.selected);
  const othersSpools = spools.filter((spool) => !spool.selected && !spool.activeBool);
  let pipeColour = '';
  // Order of spool rendering is important as they are drawn on top of each other
  // We want active to appear over other and selected to appear over active
  othersSpools.forEach((s) => {
    if (showPipeColour) { pipeColour = s.pipeColour; }
    s.renderSpool(context, pipeColour);
  });
  activeSpools.forEach((s) => {
    if (showPipeColour) { pipeColour = s.pipeColour; }
    s.renderSpool(context, pipeColour);
  });
  selectedSpools.forEach((s) => {
    if (showPipeColour) { pipeColour = s.pipeColour; }
    s.renderSpool(context, pipeColour);
  });
}

function plotNodes(context: CanvasRenderingContext2D, guiState: GuiState, modelState: ModelState) {
  const {
    nodes,
    spools,
    selectedRoute,
  } = modelState;

  const spoolsArray = Object.values(spools);
  let pointIds = new Set();

  if (guiState.showPipeTypesStatus) {
    if (typeof selectedRoute !== 'undefined' && guiState.isolateRouteStatus) {
      pointIds = new Set(selectedRoute.routePoints.map((point) => point.point.pointId));
      selectedRoute.renderRoute(context, true);
    } else {
      renderSpools(context, spoolsArray, true);
    }
  } else if (typeof selectedRoute !== 'undefined') {
    if (guiState.isolateRouteStatus) {
      selectedRoute.renderRoute(context, false); // draw with pressure colours
      pointIds = new Set(selectedRoute.routePoints.map((point) => point.point.pointId));
    } else if (guiState.activeRecipeStatus) {
      renderSpools(context, spoolsArray, false);
      selectedRoute.renderRoute(context, false);
    } else {
      renderSpools(context, spoolsArray, false);
    }
  } else {
    renderSpools(context, spoolsArray, false);
  }

  if (!guiState.isolateRouteStatus) {
  Object.values(nodes).forEach((node) => {
    node.renderNode(context);
  });
} else {
  Object.values(nodes).forEach((node) => {
    if (pointIds.has(node.pointId)) {
      node.renderNode(context);
    }
  });
}
}

function plotLineToTelemetry(context: CanvasRenderingContext2D, guiState: GuiState, nodes: Record<string, PCNode>) {
  // Draw line from telemetry to the node
  if (guiState.hoverId !== null && typeof guiState.hoverId !== 'undefined' && Object.keys(nodes)) {
    const { x, y } = nodes[guiState.hoverId].screenVector;
    context.lineWidth = 0.5;
    context.globalAlpha = 0.3;
    context.strokeStyle = GConstants.GREY;
    context.beginPath();
    context.moveTo(guiState.screenWidth - guiState.telemetryCanvas.width - 10 - guiState.drawerWidth, guiState.screenHeight - guiState.telemetryCanvas.height - 20);
    context.lineTo(x, y);
    context.stroke();
    context.globalAlpha = 1;
  }
}

export default function plotModel(guiState: GuiState) {
  // Plot the background and pipes
  const { modelState: { originNodes, selectedRoute, activeBackfillPlant } } = guiState;
  const {
    bufferCanvas,
    modelContext,
    bufferContext,
    screenHeight,
    screenWidth,
  } = guiState;
  plotBackground(bufferContext!, screenWidth, screenHeight);
  plotNodes(bufferContext!, guiState, guiState.modelState);
  plotOriginArrow(bufferContext!, originNodes, activeBackfillPlant);
  plotLineToTelemetry(bufferContext!, guiState, guiState.modelState.nodes);
  plotHGLTelemetry(bufferContext!, guiState);
  if (typeof selectedRoute !== 'undefined') {
    // Buzz (alcwyn): This now exists as a separate function above
    selectedRoute.findWarnings();
    selectedRoute.renderWarnings(bufferContext!);
    if (activeBackfillPlant) {
      plotColorKey(bufferContext!, activeBackfillPlant);
    }
  }
  modelContext!.drawImage(bufferCanvas, 0, 0);
}
