import { StopeDataDto, StopeLocationDto, ThroughputControlType } from 'providers/api';
import * as PCConstants from '../DiagraExtended/PCConstants';
import PCNode from '../DiagraExtended/PCNode';
import PCPipe from '../DiagraExtended/PCPipe';
import PCRoute from '../DiagraExtended/PCRoute';
import PCSpool from '../DiagraExtended/PCSpool';
import PCStope from '../DiagraExtended/PCStope';
import {
  DictionaryRange,
  PCFluidSpecification,
  RecipeState,
  Telemetry,
  BoosterPumps
} from '../typesInterfaces';
import LineGraph from './hgl/LineGraph';
import updateHGL from './hgl/updateHGL';
import { filterRoutesByIncludedNodes, generateAllRoutes, returnShortestRoute } from './setupRoutes';
import { convertSpoolsToRoutePointList } from '../genericFunctions';

const toggleNodeHelper = (node: PCNode, nodes: PCNode[]) => {
  if (nodes.includes(node)) {
    return nodes.filter((n) => n !== node);
  }

  return [...nodes, node];
};

export default class ModelState {
  nodes: Record<string, PCNode>;

  spools: Record<string, PCSpool>;

  routes: Record<string, PCRoute>;

  pipes: Record<string, PCPipe>;

  stopes: Record<string, PCStope>;

  selectedNode: PCNode | undefined;

  requiredNodes: PCNode[] = [];

  originNodes: PCNode[] | undefined;

  selectedStope: PCStope | undefined;

  selectedRoute: PCRoute | undefined;

  hglData: LineGraph;

  fluid: PCFluidSpecification;

  dictionaryRange: DictionaryRange;

  recipeState: RecipeState = {};

  nodeTelemetry: Telemetry = [];

  stopeTelemetry: Telemetry = [];

  hglTelemetry: Telemetry = [];

  pipeLegend: Telemetry = [];

  stopePipeType: string;

  onStopeSelect: (stopeId: string) => void;

  returnSelectedRoute: (spoolIds: string[]) => void;

  activeBackfillPlant: PCNode | undefined;

  onErrorShow: (err: Error) => void;

  constructor(
    nodes: Record<string, PCNode>,
    spools: Record<string, PCSpool>,
    routes: Record<string, PCRoute>,
    pipes: Record<string, PCPipe>,
    stopes: Record<string, PCStope>,
    originNodes: PCNode[] | undefined,
    hglData: LineGraph,
    fluid: PCFluidSpecification,
    dictionaryRange: DictionaryRange,
    defaultBackfillPlantNodeId: string,
    onStopeSelect: (stopeId: string) => void,
    returnSelectedRoute: (spoolIds: string[]) => void,
    onErrorShow: (err: Error)=> void,
  ) {
    this.nodes = nodes;
    this.spools = spools;
    this.routes = routes;
    this.pipes = pipes;
    this.stopes = stopes;
    this.originNodes = originNodes;
    this.requiredNodes = originNodes && originNodes.length ? originNodes : []; // TODO this shouldnt be required now as all routes should reach surface
    this.hglData = hglData;
    this.fluid = fluid;
    this.dictionaryRange = dictionaryRange;
    this.stopePipeType = pipes[0].name;
    this.onStopeSelect = onStopeSelect;
    this.returnSelectedRoute = returnSelectedRoute;
    this.onErrorShow = onErrorShow;

    if (originNodes && originNodes.length) {
      const item = defaultBackfillPlantNodeId ? originNodes.find((x) => x.id === defaultBackfillPlantNodeId) : originNodes[0];
      item && this.setActiveBackfillPlant(item);
    }
  }

  startAddStopeWorkflow(node: PCNode) {
    console.log('startAddStopeWorkFlow');
    // this should produce necessary stope data but the new stope is not available
    this.onStopeSelect(`${node.pointId}`);
    // this.setSelectedStope(node.pointId); // this is called already in selecting node
    Object.values(this.nodes).forEach((_n) => {
      const n = _n;
      n.isRequired = false; // do this last if necessaryundefined
    });
  }

  completeAddStopeWorkflow(stopeLocation: StopeLocationDto, stopeData: StopeDataDto) {
    // pass in new stope
    // update this.stopes
    const node = this.nodes[stopeLocation.nodeId];
    const newStope = new PCStope(node, stopeData);
    this.stopes = { ...this.stopes, [stopeLocation.nodeId]: newStope };
    console.log('finished add stope workflow', this.selectedRoute);
    this.setSelectedStope(stopeLocation.nodeId);
    updateHGL(this.hglData, this.selectedRoute, this.recipeState);
  }

  updateStope(stopeLocation: StopeLocationDto, stopeData: StopeDataDto) {
    this.stopes[stopeLocation.nodeId].updateData(stopeData);
    this.setSelectedStope(stopeLocation.nodeId);
    updateHGL(this.hglData, this.selectedRoute, this.recipeState);
  }

  updateRoutingWithNode(_node: PCNode, lastSelectedStopeId: string, isBackFillPlantNode: boolean) {
    try {
    const node = _node;
    const { selectedRoute, requiredNodes } = this;

    if (!isBackFillPlantNode) {
      if (lastSelectedStopeId) {
        this.onStopeSelect(`${lastSelectedStopeId}`);
      } else {
          this.onStopeSelect(`${node.pointId}`);
      }
    } else {
      this.onStopeSelect(`${node.pointId}`);
    }

      this.setSelectedRoute(selectedRoute);
      this.requiredNodes = requiredNodes;
      let tempRequiredNodes = isBackFillPlantNode ? [] : [...this.requiredNodes];

      if (Object.keys(this.routes).length > 1) {
        // Remove node if it exists add node if it doesn't
        tempRequiredNodes = toggleNodeHelper(node, tempRequiredNodes);

        const possibleRoutes = filterRoutesByIncludedNodes(this.routes, tempRequiredNodes);
        if (Object.keys(possibleRoutes).length > 0) {
          node.isRequired = false; // in case removed
          Object.values(tempRequiredNodes).forEach((_n) => {
            const n = _n;
            n.isRequired = true;
          });
          const short = returnShortestRoute(possibleRoutes);
          this.setSelectedRoute(short);
          this.requiredNodes = [...tempRequiredNodes];
          this.selectedNode = node;
          node.selected = true;
        } else {
          // don't select the node
        }
      }
    } catch (ex: unknown) {
      if (ex instanceof Error) {
        this.onErrorShow(ex);
        this.unselectStope();
      }
    }
  }

  clearNodeSelection() {
    Object.values(this.nodes).forEach((_n) => {
      const n = _n;
      n.selected = false; // do this last if necessary
    });
  }

  // Methods listen to changes and make any changes required
  setSelectedNode(nodeId: undefined | string | number, updateApp: boolean = false, guiState: any /* to avoid circular dependancy */) {
    const nodeType = typeof nodeId;
    const lastSelectedStopeId = guiState.addStopeStatus ? '' : guiState.modelState.selectedStope?.originNode?.pointId;

    if (!['string', 'number', 'undefined'].includes(nodeType)) return;

    console.log('ModelState.setSelectedNode', nodeId);
    // update using dict
    const node = Object.values(this.nodes).find((n) => n.pointId === nodeId);
    if (!node) return;

    if (!guiState.addStopeStatus || !node.isStope) {
      this.updateRoutingWithNode(node, lastSelectedStopeId as string, guiState.selectBackfillPlant);
    }

    if (node.isStope) {
      this.setSelectedStope(node.pointId);
      if (updateApp) this.startAddStopeWorkflow(node);
    }

    this.clearNodeSelection();
  }

  setSelectedStope(pointId: string | undefined) {
    try {
      console.log('setSelectedStope', pointId);
      if (!pointId) {
        this.selectedStope = undefined;
        return;
      }
      const selectedStope = Object.values(this.stopes).find((s) => s.originNode.pointId === pointId);
      this.selectedStope = selectedStope;
      console.log('selected stope: ', selectedStope);
      if (!selectedStope) {
        return;
      }

      this.requiredNodes = [];
      // Generate routes on selection of a stope
      this.routes = generateAllRoutes(this.activeBackfillPlant!, this.spools, selectedStope, this.pipes);
      Object.values(this.routes).forEach((_r) => {
        const r = _r;
        r.updateRoutePointData(this.recipeState);
        r.stope = selectedStope;
      });
      Object.values(this.spools).forEach((_spool) => {
        const spool = _spool;
        spool.activeBool = false;
      });
      Object.values(this.routes).forEach((route) => {
        route.activate();
      });
      this.setSelectedRoute(returnShortestRoute(this.routes));
    } catch (ex: unknown) {
      if (ex instanceof Error) {
        this.onErrorShow(ex);
        this.unselectStope();
      }
    }
  }

  setSelectedBackfillPlant(node: PCNode, guiState: any) {
    try {
      this.setActiveBackfillPlant(node);
      this.requiredNodes = [];
      // Generate routes on selection of a backfillPlant
      const lastStopeSelected = guiState.modelState.selectedStope?.originNode?.pointId;
      const selectedStope = Object.values(this.stopes).find((s) => s.originNode.pointId === lastStopeSelected);
      this.routes = generateAllRoutes(node, this.spools, selectedStope!, this.pipes);
      Object.values(this.routes).forEach((_r) => {
        const r = _r;
        r.updateRoutePointData(this.recipeState);
        r.stope = selectedStope!;
      });
      Object.values(this.spools).forEach((_spool) => {
        const spool = _spool;
        spool.activeBool = false;
      });
      Object.values(this.routes).forEach((route) => {
        route.activate();
      });
      this.setSelectedRoute(returnShortestRoute(this.routes));
    } catch (ex: unknown) {
      if (ex instanceof Error) {
        this.onErrorShow(ex);
        this.unselectStope();
      }
    }
  }

  setSelectedRoute(route: PCRoute | undefined) {
    // all routes do not contain all spools
    // so deselect spools separetely from the route list
    Object.values(this.spools).forEach((_s) => {
      const s = _s;
      s.selected = false;
      s.colour = PCConstants.SPOOL_COLOUR;
    });
    Object.values(this.routes).forEach((r) => {
      r.deselect(); // deselect all routes
      r.activate();
    });
    this.selectedRoute = route;
    if (typeof route !== 'undefined') {
      route.updateRoutePointData(this.recipeState);
      route.select();
      updateHGL(this.hglData, route, this.recipeState);
      const spoolIdList = route.routePointStrings;
      console.log(route.routePointStrings);
      this.returnSelectedRoute(spoolIdList);
    } else {
      // When unselecting calling deactivate ensures bold line is removed
      Object.values(this.routes).forEach((r) => {
        r.deactivate();
      });
    }
  }

  selectRouteFromSpoolIds(spoolIds: string[] | undefined) {
    // if undefined then clear route and returm early
    if (!spoolIds) {
      this.setSelectedRoute(undefined);
      return;
    }

    const routePointList = convertSpoolsToRoutePointList(spoolIds);

    if (routePointList.length > 1) {
      Object.values(this.routes).forEach((r) => {
        const currentRouteNodeIds = r.routePointStrings;
        if (currentRouteNodeIds.length === routePointList.length && currentRouteNodeIds.every((n, i) => n === routePointList[i])) {
          // select the route
          this.setSelectedRoute(r);
        }
      });
    }
  }

  unselectStope() {
    console.log('unselectStope');
    this.setSelectedStope(undefined);
    this.setSelectedRoute(undefined);
    this.nodeTelemetry = [];
    this.hglData.data = [];
    this.hglTelemetry = [];
    this.stopeTelemetry = [];
  }

  deselectNodes() {
    Object.values(this.nodes).forEach((n) => {
      const node = n;
      node.selected = false;
    });
  }

  setNodeAsStope(nodeId: string, isPit: boolean, isFrictionLoop: boolean) {
    const node = Object.values(this.nodes).find((n) => n.pointId === nodeId);
    if (typeof node !== 'undefined' && !isPit && !isFrictionLoop) {
      node.isStope = true;
    }
    if (typeof node !== 'undefined' && isFrictionLoop) {
      node.isFrictionLoop = true;
    }
    if (typeof node !== 'undefined' && isPit) {
      node.isPit = true;
    }
  }

  unsetNodeAsStope(nodeId: string) {
    const node = Object.values(this.nodes).find((n) => n.pointId === nodeId);

    if (typeof node !== 'undefined') {
      node.isStope = false;
      Object.values(this.routes).forEach((r) => {
        r.deselect(); // deselect all routes
        r.deactivate();
      });

      if (this.stopes[nodeId]) delete this.stopes[nodeId];

      this.selectedRoute = undefined;
      this.routes = {};
      this.hglData.data = [];
    }
  }

  setRecipeState(recipeState: RecipeState) {
    this.recipeState = recipeState;
  }

  setPumpPressure(pumpPressureKpa: number) {
    this.recipeState.pumpPressureKpa = pumpPressureKpa;
  }

  setThroughputControlType(throughputControlType: ThroughputControlType) {
    this.recipeState.throughputControlType = throughputControlType;
  }

  setBoosterPumps(boosterNodes: Record<string, PCNode>) {
    this.recipeState.boosterPumps = this.recipeState.boosterPumps ?? [];

    Object.values(boosterNodes).forEach((boosterPumpNode) => {
      const boosterPump: BoosterPumps = {
        name: boosterPumpNode.pointId,
        pressureLimitKpa: boosterPumpNode.boosterPressure * 100,
        pressureKpa: 0,
      };
      this.recipeState.boosterPumps!.push(boosterPump);
    });
  }

  setActiveBackfillPlant(node: PCNode) {
    this.activeBackfillPlant = node;
  }
}
