import { BackfillWarningDto } from 'providers/api';
import * as GlobalConstants from '../GConstants';
import {
  NO_WARNING,
  OVER_PRESSURE_POSSIBLE_WARNING,
  OVER_PRESSURE_WARNING,
  RangeBase,
  RecipeState,
  RoutePoint,
  RouteWarning,
  SLACK_FLOW_POSSIBLE_WARNING,
  SLACK_FLOW_WARNING
} from '../typesInterfaces';
import * as PCConstants from './PCConstants';
import PCNode from './PCNode';
import PCPipe from './PCPipe';
import PCSpool from './PCSpool';
import PCStope from './PCStope';

const OVER_PRESSURE_MIN = 1;
const OVER_PRESSURE_POSSIBLE_MIN = 0.8;
const NO_WARNINGS_MIN = 0.1;
const SLACK_FLOW_POSSIBLE_MIN = 0.01;

const OVER_PRESSURE_POSSIBLE: RangeBase = { min: OVER_PRESSURE_POSSIBLE_MIN, max: OVER_PRESSURE_MIN };
const NO_WARNINGS: RangeBase = { min: NO_WARNINGS_MIN, max: OVER_PRESSURE_POSSIBLE_MIN };
const SLACK_FLOW_POSSIBLE: RangeBase = { min: SLACK_FLOW_POSSIBLE_MIN, max: NO_WARNINGS_MIN };

const oneIsInsideRange = (values: number[], range: RangeBase) => values.some((value) => value >= range.min && value < range.max);

export default class PCRoute {
  originNode: PCNode;

  spools: PCSpool[];

  pipeList: Record<string, PCPipe> = {};

  routePoints: RoutePoint[];

  settlingVelocity: number | null = null;

  minimumVelocity: number | null = null;

  selected = false;

  xScale = 1;

  yScale = 1;

  hover = null;

  hasSettlingWarning = false;

  active = false;

  pumpPressureKpa: number | undefined;

  stope: PCStope;

  warnings: BackfillWarningDto[] = [];

  routeLength: number;

  routePointStrings: string[];

  constructor(
    originNode: PCNode,
    stope: PCStope,
    spools: PCSpool[],
    routeLength: number,
    routePointStrings: string[],
  ) {
    this.originNode = originNode;
    this.spools = [];
    spools.forEach((sd) => {
      this.spools.push(sd);
    });
    this.routeLength = routeLength;
    this.routePointStrings = routePointStrings;
    this.routePoints = this.setupRoutePoints();
    this.stope = stope;
    this.setupPipeList();
  }

  setupPipeList() {
    this.spools.forEach((spool) => {
        const { pipe } = spool;
        if (typeof pipe !== 'undefined') this.pipeList[pipe.pipeId] = pipe;
    });
  }

  setupRoutePoints() {
    const nodeMap: Map<string, RoutePoint> = new Map();
    this.spools.forEach((spool) => {
      if (!spool.pointA || !spool.pointB) {
        throw new Error('spool does not contain point objects');
      }
      if (spool.isFlowingFromAtoB) {
        nodeMap.set(spool.pointB.pointId, { point: spool.pointB });
        nodeMap.set(spool.pointA.pointId, { point: spool.pointA });
      } else {
        nodeMap.set(spool.pointA.pointId, { point: spool.pointA });
        nodeMap.set(spool.pointB.pointId, { point: spool.pointB });
      }
    });
    return Array.from(nodeMap.values());
  }

  getNodeList(): string[] {
    const nodesSet = new Set<string>();
    this.spools.forEach((spool) => {
      if (spool.pointA && spool.pointB) {
      nodesSet.add(spool.pointA.toString());
      nodesSet.add(spool.pointB.toString());
      }
    });
    return Array.from(nodesSet);
  }

  updateRoutePointData(recipeState: RecipeState) {
    let pressure = 0; // Start at discharge
    let distanceFromPump = this.routeLength;
    for (let i = 0; i < this.routePoints.length; i += 1) {
      const previousSpool = this.spools[i - 1];
      const currentSpool = this.spools[i];
      const pipeDownstream = (i > 0) ? previousSpool.pipe : currentSpool.pipe; // No downstream pipe on discharge node
      const pipeUpstream = (i < this.spools.length) ? currentSpool.pipe : undefined; // No upstream pipe on surface node
      if (i > 0) {
        distanceFromPump -= previousSpool.length();
        pressure += previousSpool.getPressureDelta(recipeState.wetDensity!, previousSpool.isFlowingFromAtoB);
      }

      if (previousSpool && previousSpool.pointB) {
        if (previousSpool.pointB!.boosterPressure > 0) {
          pressure = 0;
        } else {
          pressure = Math.max(pressure, 0); // cant have negative pressure
        }
      }

      this.routePoints[i].distanceFromPump = distanceFromPump;
      this.routePoints[i].pressure = pressure;
      this.routePoints[i].pipeDownstream = pipeDownstream;
      this.routePoints[i].pipeUpstream = pipeUpstream;
    }
    this.pumpPressureKpa = pressure; // adds final pump pressure
  }

  findNearestNodeIdFromDistanceFromPump(distance: number) {
    const points = this.routePoints;
    let minDist = this.routeLength;
    let nearestPointId = '';
    Object.values(points).forEach((p) => {
      if (p.distanceFromPump) {
        const dist = Math.abs(p.distanceFromPump - distance);
        if (Math.abs(p.distanceFromPump - distance) < minDist) {
          minDist = dist;
          nearestPointId = p.point.pointId;
        }
      }
    });
    return nearestPointId;
  }

  // TODO: Complete this method
  findMaxPipePressure() {
    let pressureArray = this.routePoints.map((rPoint) => rPoint.pressure ?? 0);
    let maxP = null;
    // remove undefined pressures
    pressureArray = pressureArray.filter((p) => p !== undefined);
    maxP = Math.max(...pressureArray);
    return maxP;
  }

  returnSpoolIdList() {
    const spoolIdList = Object.values(this.spools).map((spool) => spool.spoolId);
    return spoolIdList;
  }

  deselect() { // no longer highlighted blue
    this.selected = false;
    this.spools.forEach((_spool) => {
      const spool = _spool;
      spool.colour = PCConstants.SPOOL_COLOUR;
      spool.selected = false;
    });
  }

  select() { // highlight blue or colour by pressure
    this.selected = true;
    this.spools.forEach((_spool) => {
      const spool = _spool;
      spool.colour = PCConstants.SPOOL_COLOUR;
      spool.selected = true;
    });
    this.colourRouteByPressure();
  }

  activate() {
    this.spools.forEach((_spool) => {
      const spool = _spool;
      spool.activeBool = true;
    });
  }

  deactivate() {
    this.spools.forEach((_spool) => {
      const spool = _spool;
      spool.activeBool = false;
    });
  }

  renderRoute(context: CanvasRenderingContext2D, pipeTypeBool: boolean) {
    this.spools.forEach((spool, index) => {
      if (index !== 0) { // We dont draw the first spool which is the stope pipe spool
        if (pipeTypeBool) {
          spool.renderSpool(context, spool.pipeColour);
        } else {
          spool.renderSpool(context, spool.colour);
        }
      }
    });
  }

  colourRouteByPressure() {
    this.warnings = [];
    const { spools } = this;
    const routePts = this.routePoints;
    const routeLen = spools.length;

    for (let i = 1; i < routeLen; i += 1) {
        const currentPoint = routePts[i];
        const previousPoint = routePts[i + 1];

        // Stop as we are at the end of the line
        if (!currentPoint.pipeUpstream) return;

        const { pipeUpstream: { pressureRating } } = currentPoint;
        const pressureA = currentPoint.pressure;
        const pressureB = previousPoint?.pressure;

        // Stop as we don't have pressure info
        if (pressureA === undefined || pressureB === undefined) return;

        const percentA = pressureA / pressureRating; // % of rating
        const percentB = pressureB / pressureRating; // % of rating

        if (percentA >= OVER_PRESSURE_MIN || percentB >= OVER_PRESSURE_MIN) {
            spools[i].colour = OVER_PRESSURE_WARNING.color;
            this.addWarning(OVER_PRESSURE_WARNING);
        }

        if (oneIsInsideRange([percentA, percentB], OVER_PRESSURE_POSSIBLE)) {
            spools[i].colour = OVER_PRESSURE_POSSIBLE_WARNING.color;
            this.addWarning(OVER_PRESSURE_POSSIBLE_WARNING);
        }

        if (oneIsInsideRange([percentA, percentB], NO_WARNINGS)) {
            spools[i].colour = NO_WARNING.color;
        }

        if (oneIsInsideRange([percentA, percentB], SLACK_FLOW_POSSIBLE)) {
            spools[i].colour = SLACK_FLOW_POSSIBLE_WARNING.color;
            if (spools[i + 1]?.boreHoleAngle! > 60) {
                this.addWarning(SLACK_FLOW_POSSIBLE_WARNING);
            }
        }

        if (percentA < SLACK_FLOW_POSSIBLE_MIN || percentB < SLACK_FLOW_POSSIBLE_MIN) {
            spools[i].colour = SLACK_FLOW_WARNING.color;
            if (spools[i + 1]?.boreHoleAngle! > 60) {
                this.addWarning(SLACK_FLOW_WARNING);
            }
        }
    }
  }

  addWarning(newWarning: RouteWarning) {
    if (!this.warnings.some((warning) => warning.code === newWarning.code)) this.warnings = [...this.warnings, newWarning];
  }

  // buzz (Alcwyn): return errors as string array
  findWarnings(): BackfillWarningDto[] {
    return this.warnings;
  }

  // BUZZ: Maybe replaced by the stope drawer
  renderWarnings(ctx: CanvasRenderingContext2D) {
    ctx.save();
    let y = 520;
    const x = 80;
    ctx.font = '12px Arial';
    if (this.warnings.length > 0) {
      ctx.globalAlpha = 0.6;
      ctx.lineWidth = 1;

      ctx.fillStyle = GlobalConstants.GREY;
      ctx.fillText('FAULTS', x + 1, y - 5);

      if (this.warnings.some((warning) => warning.code === 'SF')) {
        ctx.fillStyle = GlobalConstants.PURPLE;
        ctx.fillRect(x, y, 82, 15);
        ctx.fillStyle = GlobalConstants.BLACK;
        ctx.fillText('SLACK FLOW', x + 2, y + 12);
        y += 18;
      }
      if (this.warnings.some((warning) => warning.code === 'OP')) {
        ctx.fillStyle = GlobalConstants.RED;
        ctx.fillRect(x, y, 107, 15);
        ctx.fillStyle = GlobalConstants.BLACK;
        ctx.fillText('OVER PRESSURE', x + 2, y + 12);
      }
    }
    ctx.restore();
  }

  setSettlingVelocity(v: number) {
    this.settlingVelocity = v;
  }

  setMinimumVelocity(v: number) {
    this.minimumVelocity = v;
  }
}
